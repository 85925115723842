import { InputLabel, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { AutocompleteElement } from 'react-hook-form-mui'
import { NodesType } from 'ts-utils'

import { SearchOwnersQuery, useSearchOwnersQuery } from 'graphql/search/SearchOwners.gen'

import { createAccountFilter } from 'hooks/useAccountSearchForm'

import { SearchParams } from '../SearchProvider'

type UserOption = NodesType<SearchOwnersQuery['users']>

type Props = {
  name: keyof SearchParams
  label: ReactNode
  accountId: string
  multiple?: boolean
}

export default function SearchPersonAutocomplete({
  name,
  label,
  accountId,
  multiple = false,
}: Props) {
  const { data, loading } = useSearchOwnersQuery({
    variables: {
      filter: { ...createAccountFilter(accountId) },
    },
  })
  return (
    <Stack direction="row" alignItems="center">
      <InputLabel htmlFor={`${name}-users`} sx={{ minWidth: 160, paddingY: 1 }}>
        {label}
      </InputLabel>
      <AutocompleteElement
        matchId
        name={name}
        loading={loading}
        multiple={multiple}
        options={data?.users?.nodes ?? []}
        autocompleteProps={{
          fullWidth: true,
          limitTags: 1,
          size: 'small',
          id: `${name}-users`,
          filterSelectedOptions: true,
          getOptionLabel: (option: UserOption) => {
            if (!option) return ''
            return `${option.fullname} (${option.email})`
          },
        }}
        textFieldProps={{
          size: 'small',
          color: 'secondary',
          variant: 'outlined',
          placeholder: 'Enter a name or email address',
        }}
      />
    </Stack>
  )
}
