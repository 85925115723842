import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import { IconButton, Tooltip } from '@mui/material'
import NextLink from 'next/link'

import { routes } from 'routes'

import { buttonStyles } from './navStyles'

export default function LogoutButton() {
  return (
    <NextLink href={routes.logout} passHref>
      <Tooltip title="Logout">
        <IconButton
          sx={{
            ...buttonStyles,
            overflow: 'hidden',
            border: (theme) => `1px solid ${theme.palette.primary.light}`,
          }}>
          <EastOutlinedIcon sx={{ fontSize: '1.2rem', marginLeft: -1, marginTop: '1px' }} />
        </IconButton>
      </Tooltip>
    </NextLink>
  )
}
