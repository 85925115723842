import NextLink from 'next/link'

import { routes } from 'routes'

import Logo from 'assets/Logo'

export default function HomeButton() {
  return (
    <NextLink href={routes.root}>
      <Logo size={50} />
    </NextLink>
  )
}
