import {
  ListItem,
  ListItemAvatar,
  listItemAvatarClasses,
  ListItemButton,
  ListItemText,
  Link as MuiLink,
} from '@mui/material'
import NextLink from 'next/link'
import { NodesType } from 'ts-utils'

import { routes } from 'routes'

import { SharedProjectsQuery } from 'graphql/notifications/SharedProjects.gen'

import ProjectIcon from 'assets/ProjectIcon'
import { formatSignInDate } from 'utils/date-time'

type Props = NodesType<SharedProjectsQuery['projectMemberships']>

export default function NotificationItem({
  id,
  createdAt,
  projectId,
  createdByUser,
  project,
}: Props) {
  return (
    <ListItem id={`notification-${id}`} disablePadding>
      <MuiLink
        component={NextLink}
        href={routes.npt.projectById(projectId)}
        sx={{ width: '100%' }}
        underline="none"
        passHref>
        <ListItemButton>
          <ListItemAvatar sx={{ [`&.${listItemAvatarClasses.root}`]: { minWidth: '2rem' } }}>
            <ProjectIcon sx={{ width: '18px', height: '18px' }} />
          </ListItemAvatar>
          <ListItemText
            primary={project?.name}
            secondary={`Shared ${formatSignInDate(createdAt)} by ${createdByUser?.fullname}`}
          />
        </ListItemButton>
      </MuiLink>
    </ListItem>
  )
}
