import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectDataQueryVariables = Types.Exact<{
  projectId: Types.Scalars['UUID'];
  userId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;


export type ProjectDataQuery = { __typename?: 'Query', project: { __typename?: 'Project', id: string, name: string, value: string | null, parentId: string | null, folderId: string | null, fileType: string, objectivesType: Types.ObjectivesType, accountId: string, isOpen: boolean | null, isActive: boolean | null, isShared: boolean | null, isArchived: boolean | null, isDeleted: boolean, teamId: string | null, projectMemberships: { __typename?: 'ProjectMembershipsConnection', nodes: Array<{ __typename?: 'ProjectMembership', isEditor: boolean, isOwner: boolean }> } } | null };


export const ProjectDataDocument = gql`
    query ProjectData($projectId: UUID!, $userId: UUID) {
  project(id: $projectId) {
    id
    name
    value
    parentId
    folderId
    fileType
    objectivesType
    accountId
    isOpen
    isActive
    isShared
    isArchived
    isDeleted
    projectMemberships(condition: {userId: $userId}) {
      nodes {
        isEditor
        isOwner
      }
    }
    teamId
  }
}
    `;

/**
 * __useProjectDataQuery__
 *
 * To run a query within a React component, call `useProjectDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDataQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProjectDataQuery(baseOptions: Apollo.QueryHookOptions<ProjectDataQuery, ProjectDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDataQuery, ProjectDataQueryVariables>(ProjectDataDocument, options);
      }
export function useProjectDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDataQuery, ProjectDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDataQuery, ProjectDataQueryVariables>(ProjectDataDocument, options);
        }
export type ProjectDataQueryHookResult = ReturnType<typeof useProjectDataQuery>;
export type ProjectDataLazyQueryHookResult = ReturnType<typeof useProjectDataLazyQuery>;
export type ProjectDataQueryResult = Apollo.QueryResult<ProjectDataQuery, ProjectDataQueryVariables>;