import { Box, Paper, SxProps } from '@mui/material'

import { getVideoURL } from 'utils/getVideoURL'

type Props = {
  videoId: string
  title?: string
  sx?: SxProps
}

export default function VideoEmbed({ videoId, title, sx = {} }: Props) {
  const videoSrc = getVideoURL(videoId || '', true)
  return (
    <Paper
      square
      elevation={0}
      sx={{
        bgcolor: 'bg.dark',
        color: 'white',
        ...sx,
      }}>
      <Box sx={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          src={videoSrc}
          allow="autoplay; fullscreen; picture-in-picture"
          loading="eager"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
          title={title}></iframe>
      </Box>
    </Paper>
  )
}
