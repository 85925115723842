import { Box, Typography } from '@mui/material'

type Props = {
  phrase: string
  author: string
}
export default function Quote({ phrase, author }: Props) {
  if (!phrase) return null
  return (
    <Box mb={2}>
      <Typography variant="subtitle1" component="q" fontSize={15}>
        {phrase}
      </Typography>
      <Typography variant="subtitle2" textAlign="right" fontSize={14} mt={2} pr={2}>
        {author}
      </Typography>
    </Box>
  )
}
