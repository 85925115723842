import { Box, Stack, Typography } from '@mui/material'
import upperFirst from 'lodash/upperFirst'
import { useRouter } from 'next/router'
import { MouseEventHandler, PropsWithChildren, useCallback, useMemo } from 'react'

import { baseProjectsRoute, routes, sectionToTab } from 'routes'

import { ProjectSection } from 'graphql/types'

import { useProject } from 'components/projects/ProjectProvider'
import { colors } from 'theme/colors'

type Props = PropsWithChildren<{
  section: ProjectSection
}>

export default function MessageGroup({ section, children }: Props) {
  const router = useRouter()

  const active = useMemo(() => {
    if (section === ProjectSection.Dashboard) {
      return router.route === baseProjectsRoute
    }
    return router.route.includes(section.toLowerCase())
  }, [router.route, section])

  return (
    <Box
      sx={{
        py: 2,
        px: '13px',
        position: 'relative',
        borderTop: `1px solid ${colors.borderDark}`,
        borderLeft: `7px solid ${active ? colors.yellowDark : 'transparent'}`,
      }}>
      {!active && <GroupOverlay section={section} />}
      <Typography variant="h5" pb={2}>
        {upperFirst(section.toLowerCase())}
      </Typography>
      <Stack direction="column" spacing={3}>
        {children}
      </Stack>
    </Box>
  )
}

const GroupOverlay = ({ section }: { section: ProjectSection }) => {
  const router = useRouter()
  const { projectId } = useProject()

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    router.push(routes.npt.projectById(projectId, sectionToTab(section)))
  }, [projectId, router, section])

  return (
    <Box
      onClick={handleClick}
      sx={{
        inset: 0,
        zIndex: 50,
        left: '-7px',
        cursor: 'pointer',
        position: 'absolute',
        '&:hover': { backgroundColor: 'rgba(255,255,255,0.05)' },
      }}
    />
  )
}
