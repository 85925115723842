import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { Button, ButtonGroup, Collapse, IconButton } from '@mui/material'
import { MouseEventHandler, RefObject } from 'react'

type Props = {
  open: boolean
  mentionRef: RefObject<HTMLButtonElement>
  onCancel: MouseEventHandler<HTMLButtonElement>
  onSubmit: MouseEventHandler<HTMLButtonElement>
  onMention: MouseEventHandler<HTMLButtonElement>
}
export default function ChatToolbar({ mentionRef, open, onMention, onCancel, onSubmit }: Props) {
  return (
    <Collapse in={open}>
      <ButtonGroup sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <IconButton size="small" onClick={onMention} ref={mentionRef}>
          <AlternateEmailIcon />
        </IconButton>
        <div>
          <Button variant="text" onClick={onCancel}>
            CANCEL
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={onSubmit}
            title="Cmd/Ctrl + Enter to send">
            OK
          </Button>
        </div>
      </ButtonGroup>
    </Collapse>
  )
}
