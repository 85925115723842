import { Badge, BadgeProps, IconButton, IconButtonProps, Tooltip } from '@mui/material'

import ChatIcon from 'assets/ChatIcon'

import { badgeStyles } from './navStyles'

type Props = IconButtonProps & {
  badgeProps: BadgeProps
}
export default function ChatButton({ badgeProps, ...props }: Props) {
  return (
    <Tooltip title="Chats">
      <IconButton {...props}>
        <Badge
          variant="standard"
          componentsProps={{ badge: { style: badgeStyles } }}
          {...badgeProps}>
          <ChatIcon color="primary" sx={{ fontSize: '1.5rem' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
