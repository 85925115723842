import { MenuItem } from '@mui/material'

import ControlLabelTextField from 'components/form/ControlLabelTextField'

export default function DateModifiedSelect() {
  return (
    <ControlLabelTextField
      select
      name="dateModified"
      label="Date modified"
      id="date-modified-search"
      sx={{ minWidth: 210 }}>
      <MenuItem value="anytime">Anytime</MenuItem>
      <MenuItem value="1d">Day Ago</MenuItem>
      <MenuItem value="1w">Week Ago</MenuItem>
      <MenuItem value="1m">Month Ago</MenuItem>
    </ControlLabelTextField>
  )
}
