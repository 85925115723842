import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'

export type ControlNumberFieldProps = {
  name: string
  limit?: number
} & TextFieldProps

export default function ControlNumberField({
  name,
  label,
  limit = 100,
  ...props
}: ControlNumberFieldProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          fullWidth
          id={name}
          type="number"
          size="small"
          color="secondary"
          variant="outlined"
          label={label}
          value={value}
          error={!!error}
          sx={{ minHight: 37 }}
          InputProps={{ inputMode: 'numeric' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseInt(e.currentTarget.value)
            if (!isNaN(value)) {
              const selectedValue = value > limit ? limit : value
              onChange(selectedValue)
            }
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = Number(e.currentTarget.value)
            if (value > limit) {
              onChange(limit)
            }
          }}
          helperText={error ? error.message : null}
          {...props}
        />
      )}
    />
  )
}
