import LogoutIcon from '@mui/icons-material/Logout'
import { Button } from '@mui/material'
import { signIn, useSession } from 'next-auth/react'
import router from 'next/router'
import { destroyCookie } from 'nookies'

import { cookieDomain } from 'external-routes'
import { routes } from 'routes'

import { useToast } from 'components/toast/ToastProvider'

export default function ReturnToYourAccount() {
  const session = useSession()
  const { addToast } = useToast()
  const handleClick = async () => {
    try {
      const resp = await signIn<'credentials'>('JWT', {
        token: session?.data?.prevToken,
        redirect: false,
      })
      if (resp?.error) {
        throw new Error(resp.error)
      }

      destroyCookie(null, 'zendeskChatToken', {
        path: '/',
        domain: cookieDomain,
      })

      addToast({ color: 'success', message: `Logging back with your account.` })
      router.push(routes.root)
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }
    }
  }
  return (
    <Button
      variant="contained-secondary"
      size="small"
      onClick={handleClick}
      endIcon={<LogoutIcon />}>
      To admin
    </Button>
  )
}
