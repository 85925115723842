import { Box, BoxProps } from '@mui/material'
import { PropsWithChildren } from 'react'
import { RenderElementProps, useFocused, useSelected } from 'slate-react'

import { colors } from 'theme/colors'

import { MentionElement } from '../slate/editor'

type MentionProps = PropsWithChildren<{
  attributes: BoxProps
  element: MentionElement
}>
export default function Mention({ attributes, children, element }: MentionProps) {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <Box
      component="span"
      {...attributes}
      title={element.email}
      contentEditable={false}
      data-name={`mention-${element.name?.replace(' ', '-').toLowerCase()}`}
      sx={{
        margin: '0 2px',
        borderRadius: '4px',
        padding: '3px 3px 2px',
        display: 'inline-block',
        verticalAlign: 'baseline',
        backgroundColor: colors.backgroundDark,
        boxShadow: selected && focused ? `0 0 0 1px ${colors.yellowDark}` : 'none',
      }}>
      @{element.name}
      {/* rendering children is important according to https://github.com/ianstormtaylor/slate/issues/3930 */}
      {children}
    </Box>
  )
}

export const MentionComponent = (props: RenderElementProps) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'mention':
      return <Mention {...(props as MentionProps)} />
    default:
      return <p {...attributes}>{children}</p>
  }
}
