import * as Types from '../types';

import { gql } from '@apollo/client';
import { ShortUserFieldsFragmentDoc } from '../user/ShortUserFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchOwnersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserFilter>;
}>;


export type SearchOwnersQuery = { __typename?: 'Query', users: { __typename?: 'UsersConnection', nodes: Array<{ __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean }> } | null };


export const SearchOwnersDocument = gql`
    query SearchOwners($filter: UserFilter) {
  users(filter: $filter) {
    nodes {
      ...ShortUserFields
    }
  }
}
    ${ShortUserFieldsFragmentDoc}`;

/**
 * __useSearchOwnersQuery__
 *
 * To run a query within a React component, call `useSearchOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOwnersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchOwnersQuery(baseOptions?: Apollo.QueryHookOptions<SearchOwnersQuery, SearchOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchOwnersQuery, SearchOwnersQueryVariables>(SearchOwnersDocument, options);
      }
export function useSearchOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOwnersQuery, SearchOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchOwnersQuery, SearchOwnersQueryVariables>(SearchOwnersDocument, options);
        }
export type SearchOwnersQueryHookResult = ReturnType<typeof useSearchOwnersQuery>;
export type SearchOwnersLazyQueryHookResult = ReturnType<typeof useSearchOwnersLazyQuery>;
export type SearchOwnersQueryResult = Apollo.QueryResult<SearchOwnersQuery, SearchOwnersQueryVariables>;