import Clear from '@mui/icons-material/Clear'
import { IconButton } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import ControlInputBase from 'components/form/ControlInputBase'

import { SearchParams, useSearch } from '../SearchProvider'

export default function SearchInput() {
  const { hasSearchQuery, searchParams, triggerSearch, resetSearch } = useSearch()
  const { watch } = useFormContext<SearchParams>()
  const searchInput = watch('name')
  return (
    <ControlInputBase
      name="name"
      fullWidth
      placeholder="Search projects…"
      inputProps={{
        'aria-label': 'search-projects',
        onKeyDown: (e) => {
          // Submit search on Enter
          if (e.key === '13' || e.key === 'Enter') {
            e.preventDefault()
            if (searchInput || hasSearchQuery) {
              // Send current search input inline as form sends data on submit
              triggerSearch({ ...searchParams, name: searchInput })
            } else {
              // if no value is submitted, reset the form
              resetSearch()
            }
          }
        },
      }}
      endAdornment={
        hasSearchQuery ? (
          <IconButton sx={{ color: 'white' }} onClick={() => resetSearch()}>
            <Clear />
          </IconButton>
        ) : undefined
      }
      sx={{
        color: 'primary.light',
        ml: 1,
        '& .MuiInputBase-input': (theme) => ({
          // https://github.com/mui/material-ui/issues/14427#issuecomment-466054906
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
          padding: theme.spacing(0, 0.5),
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          fontSize: '1.1rem',
          fontWeight: 400,
        }),
      }}
    />
  )
}
