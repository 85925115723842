import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

import { buttonStyles } from './navStyles'

type Props = IconButtonProps
export default function FaqButton(props: Props) {
  return (
    <Tooltip title="FAQ">
      <IconButton
        {...props}
        sx={{
          ...buttonStyles,
          border: (theme) => `1px solid ${theme.palette.primary.light}`,
        }}>
        <QuestionMarkIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Tooltip>
  )
}
