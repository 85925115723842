import { SxProps, Theme } from '@mui/material'
import { CSSProperties } from 'react'

import { colors } from 'theme/colors'

export const buttonStyles: SxProps<Theme> = {
  height: '1.5rem',
  width: '1.5rem',
  color: 'primary.light',
  '&:hover': {
    background: 'none',
  },
}

export const badgeStyles: CSSProperties = {
  backgroundColor: colors.brightRed,
  color: 'white',
}
