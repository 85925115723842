import { Drawer, drawerClasses, DrawerProps, ThemeProvider } from '@mui/material'
import { PropsWithChildren } from 'react'

import { colors } from 'theme/colors'
import { darkTheme } from 'theme/darkTheme'

type Props = PropsWithChildren<DrawerProps> & { drawerWidth?: string }
export default function StyledDrawer({ children, drawerWidth = '430px', ...rest }: Props) {
  return (
    <ThemeProvider theme={darkTheme}>
      <Drawer
        keepMounted
        anchor="right"
        variant="temporary"
        PaperProps={{ square: true }}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
          },
        }}
        sx={{
          zIndex: 25,
          minWidth: drawerWidth,
          flexShrink: 0,
          [`& .${drawerClasses.paper}`]: {
            pt: '85px',
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: 'bg.dark',
            backgroundImage: 'none',
            color: colors.bgDarkTextLight,
          },
        }}
        {...rest}>
        {children}
      </Drawer>
    </ThemeProvider>
  )
}
