import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import type { AutoCompleteOptionType } from 'components/form/ControlLabelAutocomplete'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(calendar)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)

export function formatMonth(date: string | number | Date) {
  return dayjs(date).format('MMMM YYYY')
}
export function formatDayMonthName(date: string | number | Date) {
  return dayjs(date).format('MMM DD')
}
export function formatDayMonthYear(date: string | number | Date) {
  return dayjs(date).format('MMM DD, YYYY')
}

export function customFormatDateToISOString(date: string | number | Date) {
  if (!date) return null

  const d = date instanceof Date ? date : new Date(date)

  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  const hours = d.getHours().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:00:00.000Z`
}

export function formatWorkshopDateOnly(date: string | number | Date) {
  if (!date) return null

  return customFormatDateToISOString(date)
}

export function formatDayMonthYearPeriod(
  startDate: string | number | Date,
  endDate: string | number | Date
) {
  return `${dayjs(startDate).format('MMM DD')} - ${dayjs(endDate).format('MMM DD, YYYY')}`
}

export function formatMonthName(date: string | number | Date) {
  return dayjs(date).format('MMM')
}
export function formatDate(date: string | number | Date) {
  return dayjs(date).format('LL')
}

export function formatTime(date: string | number | Date) {
  return dayjs(date).format('LT')
}

export function formatDateCustom(date: string | number | Date, format: string) {
  return dayjs(date).format(format)
}

export function formatDateIfPresent(date?: string | Date | null) {
  return date ? formatDate(date) : '-'
}

export function formatDateAndTime(date: string | number | Date) {
  return date ? dayjs(date).format('LL HH:mm:ss') : '-'
}

export function formatSecondsDuration(durationInSeconds?: number | null) {
  const durationFormat =
    durationInSeconds && durationInSeconds > 3599 ? 'HH[h] mm[m] ss[s]' : 'mm[m] ss[s]'
  return dayjs.duration(durationInSeconds ?? 0, 'seconds').format(durationFormat)
}

export function formatSignInDate(date: string | number | Date) {
  return dayjs(date).calendar(null, {
    sameDay: '[Today at] h:mm A', // The same day ( Today at 2:30 AM )
    lastDay: '[Yesterday at] h:mm A', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Last] dddd [at] h:mm A', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'MMMM D, YYYY', // Everything else (June 1, 2022)
  })
}

export function formatTime12Hour(input: string | number | Date, timezone?: string) {
  const time = dayjs(input, 'HH:mm:ss')
  return timezone ? time.tz(timezone).format('h:mm A') : time.format('h:mm A')
}

export function formatDateWithYear(input: string | number | Date, timezone?: string) {
  const date = dayjs(input)
  return timezone ? date.tz(timezone).format('MMM DD, YYYY') : date.format('MMM DD, YYYY')
}

export function isBeforeToday(date: Date) {
  // returns false if today
  return dayjs(date.toString()).isBefore(dayjs().startOf('day'))
}

export function addTimeToDate(date: Date, time: string) {
  // time should be a string like 11:15
  return dayjs(date.toString())
    .add(parseInt(time.slice(0, 2)), 'hour')
    .add(parseInt(time.slice(3, 5)), 'minute')
    .toDate()
}

export const getTimeOptions = (startTime = '0:00', postfix = '') => {
  const endHourInMinute = 1440
  const interval = 30
  const timeBlocksArr = [{ key: 'Select time', value: '' }]
  const [startHours, startMinutes] = startTime.split(':')
  let startHourInMinute = parseInt(startHours) * 60 + parseInt(startMinutes)

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break

    const hours = Math.floor(startHourInMinute / 60) // getting hours of day in 0-24 format

    const minutes = startHourInMinute % 60 // getting minutes of the hour in 0-55 format

    const formattedText = ('0' + (hours % 24)).slice(-2) + ':' + ('0' + minutes).slice(-2)
    const today = new Date()
    const date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hours),
      Number(minutes)
    )

    timeBlocksArr.push({
      key: `${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}${postfix}`,
      value: formattedText,
    })

    startHourInMinute = startHourInMinute + interval
  }

  return timeBlocksArr
}

// Convert minutes to the format "10hr 23m"
//
export const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  return `${hours}hr ${mins}m`
}

// Convert to timezone and can keep the time(keepTime = true) or convert the time to the timezone (keepTime = false):
//
// date // '2013-11-18T11:55:20+02:00'
// convertToTimezone(date, "America/Toronto") // '2013-11-18T11:55:20-05:00'
export const convertToTimezone = (date: Date, timezone = 'Etc/UTC', keepTime = true) => {
  return dayjs(date).tz(timezone, keepTime)
}

export const guessCurrentTimezone = () => {
  return dayjs.tz.guess()
}

export const dateComparator = (v1: string, v2: string) => {
  return dayjs(v1).diff(v2)
}

export const timezoneOptions: AutoCompleteOptionType[] = [
  { label: 'Select timezone', value: '' },
  { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
  { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
  { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
  { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
  { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
  { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua' },
  { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
  { label: '(GMT-06:00) Central America', value: 'America/Managua' },
  { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
  { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City' },
  { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
  { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota' },
  { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
  { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
  { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
  { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
  { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
  { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
  { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
  { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires' },
  { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
  { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
  { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca' },
  {
    label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    value: 'Etc/Greenwich',
  },
  {
    label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam',
  },
  {
    label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: 'Europe/Belgrade',
  },
  { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels' },
  { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo' },
  { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
  { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
  { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
  { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
  { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
  { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki' },
  { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
  { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
  { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
  { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow' },
  { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
  { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
  { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
  { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
  {
    label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi, Sri Jayawardenapura',
    value: 'Asia/Calcutta',
  },
  { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
  { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
  { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
  { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
  { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
  { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong' },
  { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
  { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
  { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
  { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra' },
  { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
  { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan' },
  { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
  { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
]
