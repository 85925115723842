import { BaseEditor, BaseRange, Descendant, Editor, Element, Range, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { ShortUserFieldsFragment } from 'graphql/user/ShortUserFields.gen'

export type SlateFormatType =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'code'
  | 'heading-one'
  | 'heading-two'
  | 'block-quote'
  | 'numbered-list'
  | 'bulleted-list'
  | 'left'
  | 'center'
  | 'right'
  | 'justify'
  | 'list-item'

export type ParagraphElement = {
  type: 'paragraph'
  align?: string
  children: Descendant[]
}
export type MentionElement = {
  type: 'mention'
  id?: string
  name?: string
  email?: string
  align?: string
  children: CustomText[]
}

export type DefaultSlateElement = {
  type: SlateFormatType
  align?: string
  children: Descendant[]
}

export type CustomElement = MentionElement | ParagraphElement | DefaultSlateElement
export type CustomEditor = BaseEditor & ReactEditor
export type CustomText = {
  bold?: boolean
  italic?: boolean
  code?: boolean
  text: string
  underline?: boolean
}

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
  }
}

export const replaceContent = (editor: Editor, content: Descendant[]) => {
  deleteContent(editor)
  // Select all so it can be replaced
  selectAllContent(editor)
  // Replace content on remote update
  Transforms.insertFragment(editor, content)
}

export const selectAllContent = (editor: Editor) => {
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  })
}

export const deleteContent = (editor: Editor) => {
  Transforms.delete(editor, {
    at: {
      anchor: Editor.start(editor, []),
      focus: Editor.end(editor, []),
    },
  })
}

export const searchContent = (editor: Editor, selection: BaseRange) => {
  const [start] = Range.edges(selection)
  const wordBefore = Editor.before(editor, start, { unit: 'word' })
  const before = wordBefore && Editor.before(editor, wordBefore)
  const beforeRange = before && Editor.range(editor, before, start)
  const beforeText = beforeRange && Editor.string(editor, beforeRange)
  const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
  const after = Editor.after(editor, start)
  const afterRange = Editor.range(editor, start, after)
  const afterText = Editor.string(editor, afterRange)
  const afterMatch = afterText.match(/^(\s|$)/)

  return { beforeRange, beforeMatch, afterMatch }
}

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid } = editor

  editor.isInline = (element: Element) => {
    return element.type === 'mention' ? true : isInline(element)
  }

  editor.isVoid = (element: Element) => {
    return element.type === 'mention' ? true : isVoid(element)
  }

  return editor
}

export const insertMention = (editor: Editor, person: ShortUserFieldsFragment) => {
  const mention: MentionElement = {
    type: 'mention',
    id: person.id,
    name: person.fullname,
    email: person.email,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, mention)
  Transforms.move(editor)
}
