import { SvgIcon, SvgIconProps } from '@mui/material'

type Props = SvgIconProps & {
  isActive?: boolean
}

export default function ProjectIcon({ isActive = true, ...props }: Props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17.902"
      viewBox="0 0 18 17.902"
      {...props}>
      <g transform="translate(0 0)">
        <path
          d="M20.5,3.049H3.5c-.276,0-.5.168-.5.373V20.578c0,.205.224.373.5.373h17c.276,0,.5-.168.5-.373V3.422C21,3.217,20.776,3.049,20.5,3.049ZM7.01,16.5a.5.5,0,0,1,.49-.451h9a.453.453,0,1,1,0,.9h-9A.5.5,0,0,1,7.01,16.5Zm9.49-2.549h-9a.453.453,0,1,1,0-.9h9a.453.453,0,1,1,0,.9Zm0-3h-9a.453.453,0,1,1,0-.9h9a.453.453,0,1,1,0,.9Zm0-3h-9A.5.5,0,0,1,7.01,7.5a.5.5,0,0,1,.49-.451h9a.5.5,0,0,1,.49.451A.5.5,0,0,1,16.5,7.951Z"
          transform="translate(-3 -3.049)"
          fill={isActive ? '#f5c23b' : '#A9A9A9'}
        />
      </g>
    </SvgIcon>
  )
}
