import { Box, Divider, PaperProps, Popover, Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { NodesType } from 'ts-utils'

import { SharedProjectsQuery } from 'graphql/notifications/SharedProjects.gen'

import NotificationsButton from 'components/layouts/nav/NotificationsButton'
import { internalShadows } from 'theme/shadows'

import NotificationsList from './NotificationsList'

const paperProps: Partial<PaperProps> = {
  square: true,
  sx: {
    minWidth: '430px',
    boxShadow: internalShadows.popover,
  },
}

type Props = {
  items: NodesType<SharedProjectsQuery['projectMemberships']>[]
}
export default function NotificationPopover({ items }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)
  return (
    <>
      <NotificationsButton
        onClick={handleClick}
        badgeProps={{
          // Show badge only for new project memberships
          badgeContent: items.length,
          invisible: Boolean(anchorEl),
        }}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={paperProps}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 40, horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <NotificationsHeader />
        <NotificationsList items={items} />
      </Popover>
    </>
  )
}

const NotificationsHeader = () => (
  <Box sx={{ position: 'sticky', top: 0, bgcolor: 'white', zIndex: 1 }}>
    <Typography variant="h6" fontWeight={300} sx={{ p: 2 }}>
      Projects shared with you
    </Typography>
    <Divider />
  </Box>
)
