import { CircularProgress, Menu, MenuItem, MenuProps } from '@mui/material'

import { ShortUserFieldsFragment } from 'graphql/user/ShortUserFields.gen'

import MentionItem from './MentionItem'

type Props = {
  loading: boolean
  people: ShortUserFieldsFragment[]
  selectedIndex: number
  onSelect: (personIndex: number) => void
} & Pick<MenuProps, 'open' | 'onClose' | 'anchorEl'>
export default function MentionsStaticMenu({
  open,
  onClose,
  anchorEl,
  loading,
  people,
  onSelect,
  selectedIndex,
}: Props) {
  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      {people.map((person, i) => (
        <MentionItem
          key={person.id}
          onClick={() => onSelect(i)}
          avatarUrl={person.avatarUrl}
          selected={selectedIndex === i}>
          {person.fullname}
        </MentionItem>
      ))}
      {loading && (
        <MenuItem sx={{ minWidth: '12rem', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </MenuItem>
      )}
    </Menu>
  )
}
