import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as React from 'react'

export default function CustomSettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={24} height={24} viewBox="0 0 26.773 25.875" {...props}>
      <g
        transform="translate(.5 .5)"
        fill="none"
        stroke="#bfbfbf"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}>
        <path d="M25.773 23.744a1.125 1.125 0 0 1-1.121 1.131H1.121A1.125 1.125 0 0 1 0 23.744V1.131A1.125 1.125 0 0 1 1.121 0h23.531a1.125 1.125 0 0 1 1.121 1.131Z" />
        <circle cx={2} cy={2} r={2} transform="translate(10.885 3)" />
        <path d="M16.27 5.652h6.523m-19.773 0h6.475" />
        <circle cx={2} cy={2} r={2} transform="translate(6.303 10.438)" />
        <path d="M12.268 12.438h10.525m-19.773 0h2.475" />
        <circle cx={2} cy={2} r={2} transform="translate(8.594 17.875)" />
        <path d="M14.268 19.221h8.525m-19.773 0h4.479" />
      </g>
    </SvgIcon>
  )
}
