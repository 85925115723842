import InfoIcon from '@mui/icons-material/Info'
import { FormControlLabel, FormHelperText, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { ReactNode } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type ControlLabelSwitchProps = {
  icon?: ReactNode
  name: string
  label: string
  maxWidth?: string
  tooltip?: string
} & SwitchProps

export default function ControlLabelSwitch({
  icon,
  name,
  label,
  maxWidth = '350px',
  tooltip,
  ...props
}: ControlLabelSwitchProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Stack direction="row" alignItems="center" maxWidth={maxWidth}>
            <FormControlLabel
              {...field}
              label={
                <>
                  {icon && icon}
                  {label}
                  {tooltip && (
                    <Tooltip title={tooltip} sx={{ alignContent: 'center' }}>
                      <InfoIcon sx={{ color: 'primary.light', width: '16px', height: '16px' }} />
                    </Tooltip>
                  )}
                </>
              }
              labelPlacement="start"
              componentsProps={{
                typography: {
                  sx: {
                    display: 'flex',
                    gap: 1.4,
                    flex: 1,
                    color: 'text.secondary',
                    alignItems: 'center',
                  },
                },
              }}
              sx={{ flex: 1, ml: 0 }}
              control={<Switch color="secondary" checked={field.value} {...props} />}
            />
          </Stack>
          {error && (
            <FormHelperText sx={{ maxWidth: '170px' }} error={!!error}>
              {error.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}
