import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm, WatchObserver } from 'react-hook-form'
import * as yup from 'yup'

import { useUser } from 'providers/UserProvider'
import { ALL_FILTER_VALUE } from 'utils/constants'

export type SearchType = {
  search?: string
  searchAccountIdLabel?: string
  searchAccountId?: string
}
const schema: yup.SchemaOf<SearchType> = yup.object().shape({
  search: yup.string().optional(),
  searchAccountIdLabel: yup.string().optional(),
  searchAccountId: yup.string().uuid('Account id is required').defined(),
})

const resolver = yupResolver(schema)

export const useAccountSearchForm = ({
  debouncedHandleSubmit,
  useUserAccountAsDefault = true,
  defaultAccountId,
}: {
  debouncedHandleSubmit: WatchObserver<SearchType>
  useUserAccountAsDefault?: boolean
  defaultAccountId?: string | null | undefined
}) => {
  const { user } = useUser()

  let filterAccountId = ALL_FILTER_VALUE
  if (useUserAccountAsDefault) {
    filterAccountId = user?.accountId ?? ''
  } else if (defaultAccountId) filterAccountId = defaultAccountId

  const methods = useForm<Required<SearchType>>({
    mode: 'onSubmit',
    defaultValues: {
      searchAccountIdLabel: '',
      searchAccountId: filterAccountId,
      search: '',
    },
    resolver,
  })

  const resetAccountId = () => {
    methods.setValue('searchAccountId', filterAccountId)
    methods.setValue('searchAccountIdLabel', '')
  }

  useEffect(() => {
    const subscription = methods.watch(debouncedHandleSubmit)
    return () => subscription.unsubscribe()
  }, [debouncedHandleSubmit, methods])

  const accountId = methods.watch('searchAccountId')

  return { methods, accountId, resetAccountId }
}

export const createAccountFilter = (accountId?: string | null) => {
  if (accountId) {
    if (accountId === ALL_FILTER_VALUE) {
      return { account: { id: { isNull: false } } }
    }
    return {
      accountId: { equalTo: accountId },
    }
  }
}
