import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetConsultantOrganizationAccessesQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['UUID']>;
}>;


export type GetConsultantOrganizationAccessesQuery = { __typename?: 'Query', consultantOrganizationAccessesList: Array<{ __typename?: 'ConsultantOrganizationAccess', userId: string, account: { __typename?: 'Account', id: string, name: string | null, isTeamsEnabled: boolean } | null }> | null };


export const GetConsultantOrganizationAccessesDocument = gql`
    query GetConsultantOrganizationAccesses($userId: UUID) {
  consultantOrganizationAccessesList(condition: {userId: $userId}) {
    userId
    account {
      id
      name
      isTeamsEnabled
    }
  }
}
    `;

/**
 * __useGetConsultantOrganizationAccessesQuery__
 *
 * To run a query within a React component, call `useGetConsultantOrganizationAccessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsultantOrganizationAccessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsultantOrganizationAccessesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetConsultantOrganizationAccessesQuery(baseOptions?: Apollo.QueryHookOptions<GetConsultantOrganizationAccessesQuery, GetConsultantOrganizationAccessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsultantOrganizationAccessesQuery, GetConsultantOrganizationAccessesQueryVariables>(GetConsultantOrganizationAccessesDocument, options);
      }
export function useGetConsultantOrganizationAccessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsultantOrganizationAccessesQuery, GetConsultantOrganizationAccessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsultantOrganizationAccessesQuery, GetConsultantOrganizationAccessesQueryVariables>(GetConsultantOrganizationAccessesDocument, options);
        }
export type GetConsultantOrganizationAccessesQueryHookResult = ReturnType<typeof useGetConsultantOrganizationAccessesQuery>;
export type GetConsultantOrganizationAccessesLazyQueryHookResult = ReturnType<typeof useGetConsultantOrganizationAccessesLazyQuery>;
export type GetConsultantOrganizationAccessesQueryResult = Apollo.QueryResult<GetConsultantOrganizationAccessesQuery, GetConsultantOrganizationAccessesQueryVariables>;