import { Avatar, ListItemIcon, MenuItem, MenuItemProps } from '@mui/material'

import { colors } from 'theme/colors'

type Props = MenuItemProps & {
  avatarUrl?: string | null
}

export default function MentionItem(props: Props) {
  return (
    <MenuItem sx={{ '&.Mui-selected': { bgcolor: colors.greyLight } }} {...props}>
      <ListItemIcon sx={{ '&.MuiListItemIcon-root': { minWidth: 30 } }}>
        <Avatar src={props.avatarUrl || undefined} sx={{ height: 18, width: 18 }} />
      </ListItemIcon>
      {props.children}
    </MenuItem>
  )
}
