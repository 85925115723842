import { differenceBy } from 'lodash'
import { useEffect, useState } from 'react'

import { useSharedProjectsQuery } from 'graphql/notifications/SharedProjects.gen'

import { useToast } from 'components/toast/ToastProvider'
import useUserEvent, { ChangeEvent } from 'hooks/useUserEvent'
import { useUser } from 'providers/UserProvider'

import NotificationPopover from './NotificationPopover'

export default function NotificationPanel() {
  const { userId } = useUser()
  const { addToast } = useToast()
  const [lastCount, setLastCount] = useState<number>(0)
  const { data, previousData, refetch } = useSharedProjectsQuery({
    variables: { userId: userId ?? '' },
    fetchPolicy: 'network-only',
  })
  const hasActiveProjects = data?.projectMemberships?.nodes.some(
    (project) => project.isArchived === false
  )
  useUserEvent({ refetch }, ChangeEvent.UserMembership)

  useEffect(() => {
    if (!data?.projectMemberships?.nodes) return
    setLastCount(data?.projectMemberships?.nodes.length ?? 0)
    // Trigger notification on the second fetch, not initially
    if (
      previousData !== undefined &&
      data?.projectMemberships?.nodes &&
      previousData.projectMemberships?.nodes
    ) {
      // More projects than previous => access to a project has been granted
      if (data?.projectMemberships?.nodes.length > lastCount) {
        const sharedProjects = differenceBy(
          data.projectMemberships.nodes,
          previousData.projectMemberships?.nodes,
          (pm) => {
            return pm.id
          }
        )
        if (!hasActiveProjects) return // don't show notifications for archived projects
        let message = 'A project has been shared with you!'
        if (sharedProjects.length === 1) {
          message = `"${sharedProjects[0].project?.name}" has been shared with you!`
        }

        addToast({
          color: 'info',
          message,
          duration: 7000, // A bit more time, because the message is a bit longer.
        })
        // Less projects than previous => access to a project has been revoked
      } else if (data?.projectMemberships?.nodes.length < lastCount) {
        const revokedProjects = differenceBy(
          previousData.projectMemberships?.nodes,
          data.projectMemberships.nodes,
          (pm) => {
            return pm.id
          }
        )
        if (!hasActiveProjects) return // don't show notifications for archived projects
        let message = 'A project access has been revoked from you!'
        if (revokedProjects.length === 1) {
          message = `"${revokedProjects[0].project?.name}" access has been revoked from you!`
        }

        addToast({
          color: 'info',
          message,
          duration: 7000, // A bit more time, because the message is a bit longer.
        })
      }
    }
  }, [previousData, addToast, data?.projectMemberships?.nodes, lastCount, hasActiveProjects])

  return <NotificationPopover items={data?.projectMemberships?.nodes ?? []} />
}
