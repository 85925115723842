import { useMemo } from 'react'
import { NodesType } from 'ts-utils'

import { userIsConsultantOnly, userIsSuperAdmin } from 'roles'

import { AccountsListQuery, useAccountsListQuery } from 'graphql/account/AccountsList.gen'
import { CurrentUserQuery } from 'graphql/user/CurrentUser.gen'
import {
  GetConsultantOrganizationAccessesQuery,
  useGetConsultantOrganizationAccessesQuery,
} from 'graphql/user/GetConsultantOrganizationAccesses.gen'

import ControlLabelAutocomplete, {
  AutoCompleteOptionType,
} from 'components/form/ControlLabelAutocomplete'
import Preloader from 'components/Preloader'
import { useUser } from 'providers/UserProvider'
import { ALL_FILTER_VALUE } from 'utils/constants'

import ControlSelectMultipleCheckbox from './ControlSelectMultipleCheckbox'

type Props = {
  name?: string
  label?: string
  placeholder?: string
  denseLabel?: boolean
  showAll?: boolean
  showSelect?: boolean
  multiple?: boolean
  getValueLabel?: boolean
  disabled?: boolean
  accountIdFilter?: string
  tooltip?: string
}

function getSuperAdminOptions(
  showAll: boolean,
  multiple: boolean,
  accounts: NodesType<AccountsListQuery['accounts']>[],
  showSelect: boolean = true
) {
  const options = []

  if (showAll) {
    options.push({ label: 'All', value: ALL_FILTER_VALUE })
  } else if (!multiple) {
    if (showSelect) {
      options.push({ label: 'Select', value: '' })
    }
  }

  const accountOptions =
    accounts?.map((account) => ({
      label: account.name ?? '',
      value: account.id,
    })) || []

  return [...options, ...accountOptions]
}

function getConsultantOptions(
  showAll: boolean,
  multiple: boolean,
  accounts: GetConsultantOrganizationAccessesQuery['consultantOrganizationAccessesList'],
  showSelect: boolean = true,
  user: NonNullable<CurrentUserQuery['currentUser']>
): AutoCompleteOptionType[] {
  const options: AutoCompleteOptionType[] = []

  if (showAll) {
    options.push({ label: 'All', value: ALL_FILTER_VALUE })
  } else if (!multiple) {
    if (showSelect) {
      options.push({ label: 'Select', value: '' })
    }
  }

  const accountsList = accounts ?? []
  const isCurrentUserOrgInList = accountsList.some(
    (accountItem) => accountItem.account?.id === user.accountId
  )

  return [
    ...options,
    ...(!isCurrentUserOrgInList
      ? [{ label: user.account?.name ?? '', value: user.accountId ?? '' }]
      : []),
    ...accountsList.map((accountItem) => ({
      label: accountItem.account?.name ?? '',
      value: accountItem.account?.id ?? '',
    })),
  ]
}

export default function AccountSelect({
  name = 'accountId',
  label = 'Organization',
  placeholder = 'Select an organization',
  denseLabel = false,
  showAll = false,
  multiple = false,
  getValueLabel = false,
  showSelect = false,
  disabled = false,
  accountIdFilter,
  tooltip,
}: Props) {
  const { user } = useUser()
  // handle consultant
  const { data: consultantOrgs, loading: loadingConsultantOrgs } =
    useGetConsultantOrganizationAccessesQuery({
      variables: {
        userId: user?.id,
      },
      skip: !userIsConsultantOnly(user),
    })
  // handle SuperAdmin
  const { data: superAdminOrgs, loading: loadingSuperAdminOrgs } = useAccountsListQuery(
    accountIdFilter
      ? {
          variables: {
            filter: {
              or: [
                {
                  id: {
                    equalTo: accountIdFilter,
                  },
                },
                {
                  parentAccountId: {
                    equalTo: accountIdFilter,
                  },
                },
              ],
            },
          },
          skip: !userIsSuperAdmin(user),
        }
      : {}
  )

  const options = useMemo<AutoCompleteOptionType[]>(() => {
    if (!user) return []
    if (userIsConsultantOnly(user)) {
      return getConsultantOptions(
        showAll,
        multiple,
        consultantOrgs?.consultantOrganizationAccessesList ?? [],
        showSelect,
        user
      )
    }
    return getSuperAdminOptions(
      showAll,
      multiple,
      superAdminOrgs?.accounts?.nodes ?? [],
      showSelect
    )
  }, [
    consultantOrgs?.consultantOrganizationAccessesList,
    multiple,
    showAll,
    showSelect,
    superAdminOrgs?.accounts?.nodes,
    user,
  ])

  if (loadingConsultantOrgs || loadingSuperAdminOrgs) return <Preloader isInput />
  if (options.length == 0) {
    return null
  }

  return (
    <>
      {multiple ? (
        <ControlSelectMultipleCheckbox
          label={label}
          name={name}
          options={options}
          tooltip={tooltip}
          disabled={disabled}
        />
      ) : (
        <ControlLabelAutocomplete
          name={name}
          label={label}
          disableClearable
          options={options}
          dense={denseLabel}
          placeholder={placeholder}
          getValueLabel={getValueLabel}
          disabled={disabled}
        />
      )}
    </>
  )
}
