import { Divider, InputLabel, Stack } from '@mui/material'

import ControlNumberField from 'components/form/ControlNumberField'

export default function ProjectValueSelect() {
  return (
    <Stack direction="row" alignItems="center">
      <InputLabel sx={{ minWidth: 160, py: 1 }}>Value</InputLabel>
      <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} flexGrow={1}>
        <ControlNumberField
          name="valueMin"
          placeholder="Min"
          sx={{ maxWidth: 140 }}
          limit={1000000000}
        />
        <Divider>&#8212;</Divider>
        <ControlNumberField
          name="valueMax"
          placeholder="Max"
          sx={{ maxWidth: 140 }}
          limit={1000000000}
        />
      </Stack>
    </Stack>
  )
}
