import { ObservableQuery, RefetchQueriesInclude, useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { IncludeArchivedOption } from 'graphql/types'
import { useUserUpdatesSubscription } from 'graphql/UserUpdates.gen'

import { useUser } from 'providers/UserProvider'

export enum ChangeEvent {
  UserMembership = 'userMembershipChanged',
  UserChanged = 'userChanged',
}

/**
 * Subscribes the user to project update events and trigger callback once new event is received
 *
 * useUserEvent({ refetch }, ChangeEvent.Objectives)
 *
 * useUserEvent({ refetchQueries: ['ProjectObjectives'] }, ChangeEvent.Objectives)
 *
 * @param param0
 * @param events
 */
export default function useUserEvent(
  {
    refetch,
    refetchQueries,
    includeArchived,
  }: Partial<
    Pick<ObservableQuery, 'refetch'> & {
      refetchQueries: RefetchQueriesInclude
      includeArchived: IncludeArchivedOption
    }
  >,
  ...events: ChangeEvent[]
) {
  const { userId } = useUser()
  const client = useApolloClient()
  const supportedEvents = useMemo(() => events, [events])
  const received = useRef<Map<ChangeEvent, Date>>(new Map([]))
  const queries = useMemo(() => refetchQueries ?? [], [refetchQueries])

  const { data: wsData } = useUserUpdatesSubscription({
    variables: { userId: userId ?? '' },
  })

  const callback = useCallback(async () => {
    if (refetch) {
      await refetch({ includeArchived })
    }
    if (queries.length > 0) {
      await client.refetchQueries({ include: queries })
    }
  }, [client, includeArchived, queries, refetch])

  useEffect(() => {
    const userUpdated = wsData?.userUpdated
    const newEvent = userUpdated?.id as ChangeEvent | undefined
    if (newEvent) {
      if (!(supportedEvents as string[]).includes(newEvent)) {
        // Event not important to this hook instance
        return
      }
      if (received.current.has(newEvent)) {
        // Event already exists in stack, skip
        return
      }
      // Add to stack
      received.current.set(newEvent, new Date())
      // addToast({ color: 'info', message: `Event received: ${newEvent}` })
    }
  }, [supportedEvents, wsData])

  useEffect(() => {
    for (const event of received.current.keys())
      callback().then(() => {
        // Clean from stack
        received.current.delete(event)
      })
  }, [callback, wsData])

  return null
}
