import * as Types from '../types';

import { gql } from '@apollo/client';
import { CommentFieldsFragmentDoc } from './CommentFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCommentMutationVariables = Types.Exact<{
  input: Types.UpdateProjectCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateProjectComment: { __typename?: 'UpdateProjectCommentPayload', projectComment: { __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null } | null } | null };


export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateProjectCommentInput!) {
  updateProjectComment(input: $input) {
    projectComment {
      ...CommentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;