import { Avatar, Collapse, Stack, Typography } from '@mui/material'
import isEqual from 'lodash/isEqual'
import { useMemo, useState } from 'react'
import { Descendant } from 'slate'

import { CommentFieldsFragment } from 'graphql/comment/CommentFields.gen'

import { useUser } from 'providers/UserProvider'
import { colors } from 'theme/colors'
import { formatSignInDate } from 'utils/date-time'
import { getInitials } from 'utils/helpers'

import ChatTextField, { emptyParagraph } from './ChatTextField'
import CommentMenu from './CommentMenu'
import ReplyButton from './ReplyButton'

type Props = CommentFieldsFragment & {
  replies?: {
    nodes: Props[]
  }
}

export default function ChatMessage({
  id,
  content,
  isArchived: isResolved,
  parentId,
  user,
  createdAt,
  replies,
}: Props) {
  const { userId } = useUser()
  const [open, setOpen] = useState(false)
  const [editing, setEditing] = useState(false)

  const commentData = useMemo(() => {
    if (typeof content === 'string') {
      const parsed: Descendant[] = JSON.parse(content)
      if (Array.isArray(parsed)) {
        // Remove empty paragraphs
        return parsed.filter((el) => !isEqual(el, emptyParagraph))
      }
    }
  }, [content])
  const isOwn = useMemo(() => user?.id === userId, [user?.id, userId])

  return (
    <Stack direction="row" spacing={1.75}>
      <Avatar
        src={user?.avatarUrl ?? undefined}
        sx={{ height: 30, width: 30, fontSize: 14, bgcolor: colors.yellowDark, mt: 0.5 }}>
        {getInitials(user?.fullname ?? '')}
      </Avatar>
      <Stack direction="column" flexGrow={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize={14}>{user?.fullname}</Typography>
            <Typography fontSize={12} color={colors.bgDarkText}>
              {formatSignInDate(createdAt)}
            </Typography>
          </Stack>
          <CommentMenu
            commentId={id}
            isOwn={isOwn}
            isResolved={isResolved}
            isThread={parentId === null}
            onEdit={() => setEditing(true)}
          />
        </Stack>
        {commentData && (
          <Typography fontSize={14} color={colors.bgDarkText} component="div" pb={1}>
            <ChatTextField
              id={id}
              initialContent={commentData}
              readOnly={!editing || isResolved}
              onEdit={() => setEditing(false)}
            />
          </Typography>
        )}
        {!parentId && (
          <ReplyButton
            open={open}
            repliesCount={replies?.nodes?.length ?? 0}
            onClick={() => setOpen((prev) => !prev)}
          />
        )}
        <Collapse in={open}>
          <Stack direction="column" spacing={1} mt={2}>
            {replies?.nodes?.map((message, index) => (
              <ChatMessage
                key={index}
                {...message}
                // A reply is considered archived if the parent is archived
                isArchived={message.isArchived || isResolved}
              />
            ))}
            {!isResolved && (
              <Typography fontSize={14} color={colors.bgDarkText} component="div" pb={1}>
                <ChatTextField placeholder="Write reply" replyTo={id} />
              </Typography>
            )}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  )
}
