import { InputBaseProps } from '@mui/material'

import ControlInputBase from 'components/form/ControlInputBase'

type Props = {
  name: string
} & InputBaseProps
export default function HiddenInput({ name, ...rest }: Props) {
  return <ControlInputBase readOnly name={name} type="hidden" sx={{ display: 'none' }} {...rest} />
}
