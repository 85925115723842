import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

import { colors } from 'theme/colors'

export default function ChatIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SvgIcon"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}>
      <path
        d="M23.5,17.482h-12l-4,4v-4H.5v-16h23Z"
        fill="none"
        stroke={colors.background}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <line
        x2="9"
        transform="translate(5.5 6.482)"
        fill="none"
        stroke={colors.background}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <line
        x2="13"
        transform="translate(5.5 9.482)"
        fill="none"
        stroke={colors.background}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <line
        x2="13"
        transform="translate(5.5 12.482)"
        fill="none"
        stroke={colors.background}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <rect width="24" height="24" fill="none" />
    </SvgIcon>
  )
}
