import { Theme, useTheme } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ResponsiveStyleValue } from '@mui/system'
import { Controller, useFormContext } from 'react-hook-form'

import { useWeglotTranslate } from 'providers/WeglotProvider'

export type ControlLabelTextFieldProps = {
  name: string
  dense?: boolean
  direction?: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>
} & TextFieldProps

export default function ControlLabelTextField({
  name,
  label,
  dense,
  direction = 'row',
  placeholder,
  ...props
}: ControlLabelTextFieldProps) {
  const theme = useTheme<Theme>()
  const { control } = useFormContext()
  const translatedPlaceholder = useWeglotTranslate(placeholder)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { ref, ...fieldProps } = field
        return (
          <Stack direction={direction} flex={1}>
            {label && (
              <InputLabel
                htmlFor={name}
                error={!!error}
                sx={{
                  minWidth: dense
                    ? theme.formFieldLabelWidth.dense
                    : theme.formFieldLabelWidth.default,
                  paddingY: 1,
                  whiteSpace: direction === 'column' ? 'wrap' : 'nowrap',
                }}>
                {label}
              </InputLabel>
            )}
            <TextField
              id={name}
              fullWidth
              size="small"
              color="secondary"
              variant="outlined"
              {...fieldProps}
              inputRef={ref}
              error={!!error}
              InputProps={{ sx: { height: props.multiline ? undefined : 37 } }}
              {...props}
              placeholder={translatedPlaceholder}
              // gives the possibility to pass default helperText
              helperText={props.helperText || error?.message}
            />
          </Stack>
        )
      }}
    />
  )
}
