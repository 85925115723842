import ExpandMore from '@mui/icons-material/ExpandMore'
import { Link as MuiLink, Typography } from '@mui/material'
import { MouseEventHandler } from 'react'

type Props = {
  open: boolean
  repliesCount: number
  onClick: MouseEventHandler<HTMLAnchorElement>
}

export default function ReplyButton({ open, repliesCount, onClick }: Props) {
  if (repliesCount > 0) {
    return (
      <MuiLink
        href="#"
        fontWeight={300}
        onClick={onClick}
        underline="hover"
        variant="subtitle1"
        sx={{ display: 'flex', alignItems: 'center' }}>
        <ExpandMore sx={{ fontSize: 18, transform: open ? '' : 'rotate(-90deg)' }} />
        <Typography sx={{ ml: 0.25 }}>{repliesCount} replies</Typography>
      </MuiLink>
    )
  }

  return (
    <MuiLink href="#" underline="hover" fontWeight={300} onClick={onClick} variant="subtitle1">
      Reply
    </MuiLink>
  )
}
