import { ReactNode } from 'react'

import { ProjectTabs } from 'routes'

import VideoEmbed from 'components/videos/VideoEmbed'

import Quote from './Quote'

type TutorialSection = {
  title?: string
  content?: ReactNode
}

export type TutorialTab = ProjectTabs | 'introduction'

type TutorialItem = {
  tab: TutorialTab
  video?: ReactNode
  title: string
  sections?: TutorialSection[]
}

enum Titles {
  Why = 'Why',
  WhatHow = 'What / How',
  BestPractices = 'Best Practices / Tips',
}

enum VideoIdsDev {
  Objectives = '939497930',
  Power = '939497888',
  Variables = '939497863',
  Communication = '939497812',
  Time = '939497845',
  Risk = '939497794',
  Negotiate = '939497768',
  Align = '939497751',
  ProjectLayout = '939497908',
  Introduction = '939497947',
}

enum VideoIdsProd {
  Objectives = '942002146',
  Power = '942002088',
  Variables = '942002061',
  Communication = '942001997',
  Time = '942002037',
  Risk = '942001970',
  Negotiate = '942001941',
  Align = '942001909',
  ProjectLayout = '942002112',
  Introduction = '942002168',
}

const VideoIds = process.env.NODE_ENV === 'production' ? VideoIdsProd : VideoIdsDev

export const MakerFramework = () => <b>The Maker Framework</b>
export const tutorial: TutorialItem[] = [
  {
    tab: 'introduction',
    title: 'Introduction',
    video: <VideoEmbed videoId={VideoIds.Introduction} sx={{ my: 2 }} />,
  },
  {
    tab: ProjectTabs.questions,
    title: 'Project Layout Help',
    video: <VideoEmbed videoId={VideoIds.ProjectLayout} sx={{ my: 2 }} />,
  },
  {
    tab: ProjectTabs.objectives,
    video: <VideoEmbed videoId={VideoIds.Objectives} sx={{ my: 2 }} />,
    title: 'Objectives Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <Quote
              phrase={`If you don't know where you’re going, you’re likely to end up somewhere else`}
              author="Yogi Berra"
            />
            <p>
              Having clear and concise objectives that are internally aligned is a critical first
              step in any negotiation. Your objectives serve as the foundation for success, as each
              subsequent phase in the negotiation planning process will be based on your desired
              outcome and objectives in the deal. Therefore, it’s imperative that you’re able to
              clearly articulate what that objective is and gain internal alignment around it. This
              may seem like a simple and straightforward task, however in highly complex
              organizations, where there are many competing interests and points of view, this can
              be challenging.
            </p>
            <p>
              Your objective(s) will be influenced by your position on The Negotiation Table,
              therefore it is important to pay attention to the type of negotiation you are involved
              in when creating your objectives. Having specific, measurable, achievable, realistic,
              and time bound objectives that are clearly defined and most importantly internally
              aligned is important. Ensuring everyone in the organization is aware and in agreement
              on the chosen path forward is critical. Careful thought and attention should be put
              into this initial phase, as to limit the potential for altering your objectives as the
              negotiation unfolds. It is, after all, very difficult to hit a moving target.
            </p>
            <p>
              When creating your objectives, it is also important to consider and identify all
              possible internal and external restrictions that may impede your ability to achieve
              success. Communicate those internally in an effort to remove as many of them as
              possible. And lastly, it is also important to identify the other party’s objectives as
              well. Too often negotiators focus solely on what they want and need, and fail to
              consider the other party’s wants and needs as well. As it is only by understanding the
              other party’s objectives that you can begin to understand where your leverage may lie
              and how best to exploit it or not exploit it, depending on your position on The
              Negotiation Table and your desired outcome.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>Complete all text input boxes on the page.</p>
            <p>
              Fill in your “SMART” objectives for the negotiation. You may add multiple objectives
              by using the “Add New” button at the bottom of the SMART objective section.
            </p>
            <p>
              Fill in whatever you believe to be “Their Objective” in the negotiation, even if you
              don’t know with 100% certainty, use your best judgements to make an assumption.
            </p>
            <p>
              Assess what internal and external restrictions may exist that could prevent you from
              achieving your SMART objective goal(s).
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>
              Gaining internal alignment around your objectives may be the single most important
              factor in determining your success.
            </p>
            <p>
              Alignment does not have to equal agreement. It is possible to disagree and still be in
              alignment with stated objectives.
            </p>
            <p>
              Focus less on what you want, and focus more on what the other party wants and needs.
              That is where your leverage will come from in the deal, whether you chose to use it or
              not.
            </p>
            <p>
              Be as specific as you can with your objectives as vague or unclear objectives foster
              uncertainty, create the potential for blame, drive dissension, and ultimately create
              preventable emergencies.
            </p>
            <p>
              Aligned and clear objectives provide flexibility, enable creativity, empower decision
              making, and create a ‘backstop’ for tough decisions.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.power,
    video: <VideoEmbed videoId={VideoIds.Power} sx={{ my: 2 }} />,
    title: 'Power Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <Quote phrase="Perception is reality" author="Lee Atwater" />

            <p>
              Power is one of the most misunderstood concepts in all of negotiation, and yet, it is
              one of the most important. Why? Because ultimately, if you have power, you can dictate
              many of the parameters of your negotiation. You can also (to varying degrees) impose
              your will on the other party and can choose to negotiate in the manner and style that
              you choose. However, in order to do so, you not only need to have power, but more
              importantly, you need to understand how to influence the perception of power.
            </p>
            <p>
              This is because power is not tangible. You cannot touch it or see it, and yet, it has
              a very tangible impact on the outcome of your deals. Those with power typically do
              better in their negotiations than those without power. And yet the question remains,
              what is power and how do we influence it? In truth, when we talk about power, what we
              are really talking about is the perception of power. Because ultimately, if the other
              party perceives you to be in a position of power, then by default, you are powerful.
              Similarly, if you perceive the other party as powerful, then you have given them that
              power.
            </p>
            <p>
              So power is less about actual power, and more so about perceived power. Therefore, in
              order to influence power, you need to influence perception. If you can change
              someone’s perception, you can change your power. That is the purpose of this phase.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>Begin by completing the Macro Power Assessment tool.</p>
            <p>
              Assess the 10 macro environmental factors associated with your negotiation by
              determining who they are more favorable towards - you or them.
            </p>
            <p>
              Next, assess the 10 micro environmental factors associated with your negotiation by
              determining who they are more favorable towards - you or them.
            </p>
            <p>
              Once complete, the system will calculate your overall Power score based on your
              answers to the Macro and Micro factors and provide you with feedback customized to
              your power level and negotiation type.
            </p>
            <p>
              Using the Macro and Micro power assessments as guidance, complete the Power Perception
              Shifter below.
            </p>
            <p>
              The Power Perception Shifter is a thought exercise designed to give you guidance on
              how to alter the perception of power between you and the other party.
            </p>
            <p>
              Answer each of the four sections, (you can use the Macro and Micro power assessments
              as guidance) to devise a messaging strategy that will begin to shift power in your
              favor.
            </p>
            <p>
              Once you have completed the Power Perception shifter and all the above questions, you
              are done with the “Power” phase of The Maker Framework. You will use this information
              later in the “Communication” phase to help guide and craft your messaging strategies.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>People are too quick to cede power to the other party.</p>
            <p>It is human nature to believe that the other party has more power than you do.</p>
            <p>
              Even when we do have power, oftentimes we are hesitant to use it to its fullest
              extent.
            </p>
            <p>
              We fear that exercising our power fully will have detrimental effects and impacts on
              relationships, However in most instances, the other party expects us to exercise our
              power fully. Failure to do so only results in us leaving value on the table.
            </p>
            <p>
              Power is not static. Power is dynamic. Power moves around, and just because you have
              power today does not necessarily mean you will have power tomorrow, or vice versa.
            </p>
            <p>
              When you do have power, be sure not to abuse it. Though you want to maximize your
              value potential in any negotiation, recognize that a time may come when you no longer
              have power and the other party does, and you need them more than they need you. They
              will remember how you acted when you had power over them. People have long memories.
            </p>
            <p>
              You can’t force people into believing you have power, they need to arrive at that
              conclusion on their own. The earlier you begin to shift their perception of power, the
              more effective you will be, and the more likely they are to believe you.
            </p>
            <p>
              Sometimes, if possible, it may be beneficial to delay a negotiation until you have
              sufficiently shifted the perception of power in your favor.
            </p>
            <p>
              Having more power is preferable to having less power, however you will not always be
              in an advantageous power position. Knowing when you are in a power deficit is
              important, so that you can begin to strategize accordingly.
            </p>
            <p>
              Not realizing you have power when you do can result in wasted opportunity and value
              erosion, however thinking you have power when you don’t can be equally dangerous.
            </p>
            <p>
              Oftentimes establishing a firm BATNA (Best Alternative To A Negotiated Agreement) will
              give you more power in a deal.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.variables,
    title: 'Variables Help',
    video: <VideoEmbed videoId={VideoIds.Variables} sx={{ my: 2 }} />,
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <Quote
              phrase="The wise man is he knows the relative value of things"
              author="William Ralph inge"
            />
            <p>
              In a negotiation, a variable is anything that we negotiate over. In the variables
              phase of The Maker Framework, we seek to identify, categorize, and assess the relative
              values and costs of each variable in our deal. In addition, we also set our upper and
              lower boundaries for each, what we call our “walkaway points”. Doing so allows us to
              manage complexity and trade more effectively by creating value accretive conditional
              offers.
            </p>
            <p>
              While identifying and assessing variables may seem like an obvious, straight-forward
              task, it can in fact be one of the more complex phases of the entire negotiation
              planning process. And it’s important to get it right as all your future offers will be
              based on this information.
            </p>
            <p>
              One of the reasons this phase can be so challenging for negotiators is that people
              tend to focus solely on what is in front of them, and fail to consider alternatives.
              They focus on ‘what is’, rather than ‘what could be’. In general, people have a narrow
              view of the variables in their negotiations and because of that, they tend to
              negotiate over the same variables over and over again. While not necessarily wrong, if
              you want to unlock or capture additional value in your deals, you may need to consider
              alternatives not previously discussed.
            </p>
            <p>
              It is also important to understand that variables come in two forms, tangible and
              intangible. Tangible variables are generally quantifiable in nature and their value is
              usually well understood by all negotiating parties, whereas intangible variables are
              generally qualitative in nature and their value is often less well defined.
            </p>
            <p>
              It is often the tangible quantifiable variables that negotiators focus their energy
              on, due to the fact that these variables are more obvious and their value is well
              understood. But it is for that exact reason that they are also much more contentious
              to negotiate over. After all, the value of $100 is well understood by all parties
              involved..
            </p>
            <p>
              Intangible qualitative variables on the other hand are the opposite. They are less
              obvious, and usually have a greater value discrepancy between the negotiating parties,
              which makes them preferable when value creation is the goal.
            </p>
            <p>
              It is also important to understand that not all variables are of equal value to all
              parties. Inevitably, almost every variable in a negotiation tends to be more important
              to one party than to the other, even if only slightly. This fact allows you to
              categorize your variables into two types: “Gets” and “Gives”. Where “Gets” are
              variables that are more important to you, and “Gives” are variables that are more
              important to them.
            </p>
            <p>
              Using this information, you can now begin to think about your potential trades in the
              negotiation by utilizing conditional trading. A conditional trade is one in which you
              only give something up, if you get something in return. Functionally this works by
              trading a “Get” for a “Give”. You are essentially stipulating that in order for you to
              ‘Give’ something up, you need to ‘Get’ something in return. Trading in this way
              ensures that value is returned every time you make a concession in your deal. You can
              use the following trade architecture to structure your proposals:
            </p>
            <Quote
              phrase="On the condition that (insert Get variable), we could agree to (insert GIve variable)"
              author=""
            />
            <p>
              This brings into focus why the proper categorization and classification of variables
              is so critical to the negotiation planning process, as all offers will be based upon
              this information, so it’s important you get it right.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>
              The first step in the Variables phase is to identify the variables that you will be
              negotiating over in the deal. In order to add a variable click on the + sign at the
              top of the page beside “Variables”.
            </p>
            <p>
              A pop-up window will appear. From here you can either select a variable from the drop
              down list, or if the variable you want is not listed, you can scroll to the bottom of
              the list and use the “Create New Variable” function.
            </p>
            <p>
              Next, you can choose to give the variable a descriptor in the field below. This is not
              mandatory however.
            </p>
            <p>
              Once a variable is added, the next step is to assign the variable as either a “Get”, a
              “Give”, or a “Get/Give”. Where a “Get” is more important to you, and “Give” is more
              important to them, and a “Get/Give” is equally important to both parties.
            </p>
            <p>
              Next, depending on what assignment the variable is given, you must assess the relative
              “Value” and “Cost” of the variables to all parties. The system will only allow you to
              complete the fields that are appropriate for the type of variable that was assigned.
              For “Value” and “Cost” assessments, we use a simple, “High”, “Medium”, and “Low”
              grading metric.
            </p>
            <p>
              The last step in the Variables phase is to assess your A, B, and C position for each
              variable.
            </p>
            <p>
              Your ‘A’ position can be thought of as “their walk away point”. This is what you
              believe to be their worst case scenario on that variable while still being able to say
              yes. Good for you, bad for them.
            </p>
            <p>
              Your ‘B’ position where you believe the deal will likely end off for that given
              variable. It can be thought of as a compromise for both parties.
            </p>
            <p>
              Your ‘C’ position can be thought of as “your walk away point”. This is your worst case
              scenario on that variable while still being able to say yes to the deal. You should
              always know what your walk away point is.
            </p>
            <p>
              Once you have identified, categorized and assessed the variables in your deal, you are
              done with the “Variables” phase of the Maker Framework. You will use this information
              later in the “Negotiate” phase to build your conditional trades.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>
              Never make a unilateral concession in a negotiation without getting something in
              return.
            </p>
            <p>
              Always use conditional trading in all your offers and always trade a “Get” for a
              “Give”, in that order. Doing so attaches a condition to your concession.
            </p>
            <p>
              Example: On the condition that you do (X) for me, then we could agree to do (Y) for
              you.
            </p>
            <p>
              In this example you are leading with your condition, and telling the other party what
              they need to do in order to get the thing that they want from you. If you reverse the
              order and lead with your concession, the statement becomes less powerful.
            </p>
            <p>
              When thinking about what variables are involved in a negotiation, spend 30 minutes
              brainstorming all the possible variables that could be negotiated over in the deal,
              not just the ones you have negotiated over in the past. You may discover many more
              levers to pull.
            </p>
            <p>
              Intangible variables almost always have greater value and cost discrepancies than
              tangible variables, making them (usually) less contentious to negotiate over, and
              better for creating value.
            </p>
            <p>
              You do not need to have an equal amount of “Gets” and “Gives” in your negotiation.
            </p>
            <p>
              You can have multiple “Gets” and “Gives” in any one proposal, and they do not need to
              balance. You can trade 1 “Get” for 3 “Gives”, or 4 “Gets” for 2 “Gives”. There is no
              right amount of variables to include in any proposal. What matters is that the value
              you are receiving in return balances or supasses (ideally) the cost of what you are
              giving up.
            </p>
            <p>
              Resist the urge to categorize too many variables as “Get/Gives”. Try and focus on who
              the variable is more important to, even if only slightly.
            </p>
            <p>
              The more “Get/Give” variables you have in your deal, the more contentious your
              negotiation is likely to be. Because if you are negotiating over variables that both
              parties value equally, then both parties are likely to fight equally hard for them.
            </p>
            <p>
              Your ‘walk away’ point is not your target in a negotiation. Their ‘walk away’ point is
              your target. The closer the deal ends to their ‘walk away’ point the more value you
              have secured in the deal.
            </p>
            <p>
              Negotiation is not about what you want, it is about what the other party can give you.
            </p>
            <p>If you focus on your walk away point, that is where you will end up. </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.communications,
    video: <VideoEmbed videoId={VideoIds.Communication} sx={{ my: 2 }} />,
    title: 'Communication Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <Quote
              phrase="The single biggest problem in communication is the illusion that it has taken place"
              author="George Bernard Shaw"
            />
            <p>
              In the communication phase of The Maker Framework, we address the people element of
              negotiation. Going into any deal, you need to have a firm grasp of who it is that you
              are negotiating with. Based on that information you can begin to craft your targeted
              messaging strategies to help shift power in your favor, and gain the edge in your
              deal.
            </p>
            <p>
              Effective negotiators understand that every interaction with the other party is an
              opportunity to negotiate, influence and shift power. If you wait until the formal
              negotiation process begins to attempt to impact the final results, your effectiveness
              will be significantly reduced. Your greatest opportunity to influence the outcome of a
              deal happens well before you sit down with the other party to negotiate. It is in all
              the interactions that lead up to a negotiation where the opportunity lies.
            </p>
            <p>
              But to do so effectively, you need a game plan. You need to know who the key players
              are on the other side of the table, you need to understand their styles, preferences,
              and authority levels. You need to anticipate in advance how they are going to react
              and behave, what their objections may be, what questions they will ask and what their
              alternatives are. In addition, you also need to devise your communication and
              influencing strategies, including what messaging you are going to proactively deliver
              to help shift that perceived balance of power in your favor.
            </p>
            <p>
              In a well planned out negotiation, nothing that the other party says or does should
              take you by surprise. All possibilities should have been accounted for in advance,
              with strategic responses prepared well before the event occurs. Preparing in this way
              eliminates much of the guesswork from the negotiation, and in fact, increases not only
              your preparedness but also your confidence. This way, as the negotiation begins to
              unfold, and the other party begins revealing their hand, you simply continue following
              your gameplan. Delivering your messages as appropriate and responding to their
              proposals, questions, and statements as appropriate.
            </p>
            <p>
              A prepared negotiator is a confident negotiator, and a confident negotiator is an
              effective negotiator.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>
              Begin by adding all the people that are involved in your negotiation, both from an
              internal and external perspective. Internal people are from your company, external
              people are from the party you are negotiating with.
            </p>
            <p>
              Click on “Add Connections” to get started. A popup will appear prompting you to add
              either an ‘internal’ or ‘external’ connection.
            </p>
            <p>
              Begin by adding internal connections first, fill in all respective fields for each
              individual, including:
            </p>
            <p>
              TKI Type - This classification is informed by the Thomas-Kilmann Instrument conflict
              mode assessment tool. If you have not completed a TKI assessment, select the
              negotiator type that most closely reflects the individual.
            </p>
            <p>
              Role - There are three different roles a user can occupy in a negotiation. That of
              ‘Negotiation’, ‘Escalation’, and ‘Ancillary’:
            </p>
            <ul>
              <li>
                Negotiation = those directly involved in the day to day negotiating activities
              </li>
              <li>
                Escalation = those who may become involved if things escalate. ‘Escalation 1’ being
                the first escalation point, ‘Escalation 2’ being the second and ‘Escalation 3’ being
                the third and most senior escalation point. Not all escalation points are required
                in every negotiation. However there should be at least one.
              </li>
              <li>
                Ancillary = those who are not directly involved but may be supporting the
                negotiation teams.
              </li>
            </ul>
            <p>
              LinkedIn URL (personality assessments performed via AI will be derived from LinkedIn
              profile URL. feature coming soon!)
            </p>
            <p>
              Repeat the same process as above but now for external connections who will be involved
              in the negotiation.
            </p>
            <p>Fill in all the fields in the box as before with internal, including: </p>
            <p>
              Key decision maker - note, there can only be one person designated as the “key
              decision maker” on their side.
            </p>
            <p>
              Decision maker type - We classify the other party’s decision making authority level
              using the following graphical representations:
            </p>
            <ul>
              <li>Mouse = Low Influence/Low Authority</li>
              <li>Bull = High Influence/Low Authority</li>
              <li>Owl = Low Influence/High Authority</li>
              <li>Fox = High Influence/High Authority</li>
            </ul>
            <p>
              Ideally, you want to identify who is the Fox for the other party as they are the ones
              who control the ultimate decision making.
            </p>
            <p>
              Lastly, determine who is the primary and/or secondary contact for this external
              connection. Meaning, who is the person from your side that has the primary
              relationship with this individual, and who else might have a secondary relationship.
              An external contact may only have 1 primary but may have multiple secondary contacts
              from your side. You can add secondary contacts by using the + button in the bottom
              right corner of the popup.
            </p>
            <p>Lastly, determine the trust level that exists between the contacts. Where:</p>
            <ul>
              <li>Red = Broken trust</li>
              <li>Yellow = Skeptica</li>
              <li>Green = Trusting</li>
              <li>N/A = New relationship</li>
            </ul>
            <p>Click “Create” and you are done.</p>
            <p>
              Now complete the “Key Messages” section. In this section you want to determine what
              are the key messaging themes you want the other party to hear, understand, and accept
              over the course of this negotiation. These will be the themes that you return to over
              and over again as the negotiation unfolds.
            </p>
            <p>
              Complete the “External Messaging” section. In this section you want to consider what
              message you want to send to the other party, how you will send it, who you’re sending
              it to, who is sending it, and the date you will send it.
            </p>
            <p>
              Next complete the “Internal Messaging” section. In this section you want to consider
              what messaging needs to occur internally within your own organization in order to
              ensure complete internal alignment around your strategic initiatives, goals, and
              objectives.
            </p>
            <p>
              The final section is for Questions. In this section, you will consider the questions
              that you will be asking the other party as well as the questions they will be asking
              you. Complete as many of the fields as possible in this section as it will make you
              much more prepared.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>
              It’s important to map out the relationships that exist between you and the other
              party, and fully understand what the authority levels are for those on the other side
              of the table. This will aid in determining what messages need to be sent to who and
              why.
            </p>
            <p>
              Too often negotiators waste their time “negotiating” with a disempowered individual (a
              mouse) who has no ability to say yes to them in the first place. Always know who you
              are really negotiating against, as the person you might actually need to convince (the
              fox) might be behind the scenes and not the person you are engaged with on a day to
              day basis.
            </p>
            <p>
              Beware of bulls. They will make it seem like they are decision makers but in truth,
              they don’t have the authority to say yes, but they will want you to believe that they
              do.
            </p>
            <p>
              Certain personality types are better suited for certain jobs when it comes to
              negotiation. A highly collaborative individual might be the wrong person to deliver a
              stern, hard message. Whereas a highly competitive individual may be the wrong person
              to create a collaborative, value creative atmosphere. When possible, choose your
              people wisely.
            </p>
            <p>
              Leverage existing relationships where you can. Although ‘mice’ might not be empowered,
              they do a good job of carrying messages within their own organization. And a message
              coming from their own people will always be more effective than a message coming from
              you.
            </p>
            <p>
              Credibility is established through 3rd parties. If you have an important message that
              you need to land, consider what avenues/options you have for getting that message
              delivered by someone who is arms length to you. It will be viewed with less skepticism
              coming from a seemingly independent 3rd party.
            </p>
            <p>
              Internal messaging can be just as important as external messaging. Especially in
              highly complex organizations.
            </p>
            <p>
              You are not nearly as effective as you might think you are at coming up with questions
              in the heat of the moment.
            </p>
            <p>
              Use a combination of open ended questions to get the other party to talk and divulge
              information and closed questions to vet assumptions and drive a deal towards
              completion.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.time,
    video: <VideoEmbed videoId={VideoIds.Time} sx={{ my: 2 }} />,
    title: 'Time Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <Quote phrase="Time isn't the main thing, it’s the only thing" author="Miles Davis" />
            <p>
              The time phase of The Maker Framework is one of the more direct phases in the entire
              negotiation planning process, yet, its importance cannot be understated and its
              usefulness should not be overlooked. In this phase we map out all the events,
              interactions, meetings, and negotiations that need to occur from start to finish in
              the deal. Giving everyone involved a crystal clear timeline regarding what needs to
              happen, when it needs to happen, and who needs to make it happen.
            </p>
            <p>
              The reason why time is so important, and why we devote an entire phase to it in the
              negotiation planning process, is because time can be one of the single most powerful
              sources of leverage in a negotiation. It affects everything we do, from our
              decision-making processes all the way to our internal perception of cost and value, it
              even has a dramatic influence on the balance of power between negotiating parties.
              Because of this, it is important that we understand how to manage time effectively to
              our advantage in a negotiation, and even more importantly, how to prevent it from
              being used against us.
            </p>
            <p>
              Time is such a powerful lever in negotiation because people make concessions when
              faced with time pressure. As a general rule of thumb, 80% of concessions happen in the
              last 20% of the available time in all negotiations. Instinctively, you probably know
              this to be true. The early portion of any negotiation usually involves a lot of
              posturing, but not a lot of movement. It isn’t until at least one party feels
              obligated (almost always due to time constraints) that they start making meaningful
              concessions. This phenomena holds true whether your negotiation lasts one hour, one
              day, one week, one month, one year or beyond. We refer to this as the 80/20 rule. It
              is human nature to withhold on making meaningful concessions until you feel you are
              obligated to do so due to time pressures.
            </p>
            <p>
              Knowing this, you can begin to see how time becomes a much more strategic element to a
              negotiation beyond the simple task of mapping events and creating timelines. It
              becomes a conversation of ‘how do you impart time pressures on the other party,
              without having them impart time pressures onto you?’
            </p>
            <p>
              As you can see, in and of itself, time is not a good thing or a bad thing, rather it
              all comes down to how you use it. For this reason, we need to think about time more
              strategically and we need to have a clear understanding of who is under what time
              pressures, and how we can use that information to lessen our perceived time pressures
              and increase theirs. In order to do so, we need a clearly delineated timeline of
              events that is mapped out in advance of the deal, and aligned to our strategic
              initiatives, objectives, goals, and power states.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>
              To add a new event to your timeline, begin by selecting the + sign beside “Events” in
              the upper left hand corner of the page.
            </p>
            <p>A popup will appear prompting you to first select a date and time for an event. </p>
            <p>
              Next choose the event type from the drop down list below. If the event type you desire
              is not listed, you can use the “Other” option at the bottom of the list and name the
              event yourself.
            </p>
            <p>Lastly, you can add in a descriptor for the event.</p>
            <p>
              Then hit “Create” and your event will be created and added to your timeline in
              chronological order.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>Start with the end in mind.</p>
            <p>
              It is usually easier to start with the desired end date in mind for your negotiation
              and work backwards from there to your current date.
            </p>
            <p>
              Think about all the things that need to occur between now and deal conclusion in order
              for you to be successful.
            </p>
            <p>
              No event is too small or insignificant. Even if it is only a reminder to send an email
              or make a phone call, having a clearly defined timeline serves as a helpful reminder
              for all the tasks that you need to accomplish in order to achieve success in your
              deal.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.risk,
    video: <VideoEmbed videoId={VideoIds.Risk} sx={{ my: 2 }} />,
    title: 'Risk Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <p>
              Negotiation generally requires enacting change as a means to resolve the differences
              of either party in order to reach an agreement. Whether you are the party initiating
              the proposed change or responding to the actions of your counterparty, as you prepare
              for your negotiation it is common for you and your team to be concerned about the
              things that could go wrong.
            </p>
            <p>
              Oftentimes, negotiators focus their attention on all the negative consequences that
              may result from the negotiation—business loss, relationship damage, or punitive
              actions the other party may choose to take if they need to influence the power dynamic
              as the negotiation plays out. While this type of thinking is common and completely
              normal, it could be a challenging topic to overcome if we don’t channel the
              conversation in a productive way.
            </p>
            <p>
              The risk assessment framework presents a way to evaluate the risk or problems that you
              may encounter in your negotiation in an objective and solution-oriented way. Rather
              than being consumed by risks, the framework allows you to evaluate all the things that
              could happen while keeping in mind that all risks aren’t the same. Some things are
              more likely to happen than others and not all problems would have the same impact on
              your business. By evaluating likelihood and impact for each risk, you can have a
              better feel for how significant the problem is. Lastly, the risk framework gets you
              and your team thinking about actions you can take to prevent a risk from occurring or
              actions to take to mitigate the impact of that risk if and when it materializes.
            </p>
            <p>
              This type of risk planning serves two essential purposes. The first, is grounding the
              negotiating team in the reality of the situation. Second, a risk assessment provides a
              foundation for an objective internal dialogue about ranges of actions we anticipate
              from the other side. A risk assessment will ensure that you aren’t surprised by the
              types of things you may run into as a result of the negotiation. Additionally, it
              allows you to explore how we will respond to risks before they occur and put you under
              stress.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>
              Complete the risk assessment page from left to right. First identify the potential
              risk and describe it in terms that a broad audience would understand.
            </p>
            <p>
              Next, assign a probability (on a % basis) that the risk will materialize. Where 100%
              means that the risk will absolutely occur and 0% means it will definitely not occur.
            </p>
            <p>
              Next, assess the impact on your business that the risk would have if it does
              materialize. Where 10 means a maximum negative impact (worst case scenario) and 0
              means little-to-no impact at all.
            </p>
            <p>
              Next, attempt to come up with preventative, proactive measures that you could take
              today in order to lessen the likelihood of the risk materializing.
            </p>
            <p>
              Lastly, develop mitigation action plans to lessen the impact of the risk should it
              materialize.
            </p>
            <p>
              Once completed, each risk will be assigned an impact score based on a 10 point scale,
              where the higher the score the higher the risk, as well as a risk level from low to
              very high.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>
              Likelihood of risks should be documented as a % probability. This helps to avoid
              misperception with words like “likely”, “probably”, “doubtful” and ensures clarity on
              what the likelihood of something happening is.
            </p>
            <p>
              When quantifying business impact of risks, it is helpful to think about the
              perspective of the business as a whole rather than your individual relationship. For
              example, if you are the account manager for your customer and they are your sole
              account, every risk may feel like a ‘high-impact’ risk to you. It is more appropriate
              to view risks from the perspective of your organization so that you are not over
              stating the significance of risks.
            </p>
            <p>
              In our experience, most risks will not be preventable. If it is something that is
              solely in the control of your counterparty, there is little you can do to prevent the
              risk.
            </p>
            <p>
              Mitigation actions can vary significantly based on the business impact of the risk.
              Respond proportionally to risks. High impact risks should have more significant
              mitigation actions versus low impact risks.
            </p>
            <p>Types of mitigation actions that may be deployed include:</p>
            <ul>
              <li>
                Messaging- deploying messaging themes to respond to problems that arise, including
                caution of potential consequences.
              </li>
              <li>
                Escalation- escalation of the negotiation to different stakeholders or levels of the
                organization.
              </li>
              <li>
                Change in strategy/ concessions- deploying concessions to mitigate risks or shifting
                strategies based on problems that arise.
              </li>
            </ul>
            <p>
              Ensure you align on mitigation actions with relevant internal stakeholders,
              particularly for elements that lie beyond your scope of authority.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.negotiate,
    video: <VideoEmbed videoId={VideoIds.Negotiate} sx={{ my: 2 }} />,
    title: 'Negotiate Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <p>
              This step of the process focuses on the evaluation of potential proposals, making of
              offers, and reshaping the deal package to drive toward a deal.
            </p>
            <p>
              As complexity increases in a negotiation, there are likely many potential scenarios of
              what a final deal could look like. One of the biggest challenges in this phase of your
              planning is getting a sense for what types of deal ‘packages’ or ‘scenarios’ could be
              acceptable. Rather than getting tunnel vision regarding a single view of what the deal
              should look like, planning several scenarios could help to see where the flex of ‘give
              and take’ can be possible in your deal. While it is unlikely that you can predict the
              final landing spot across all variables in the deal, use the scenario planning to
              model different paths that you can take to reach an acceptable deal that achieves your
              objectives.
            </p>
            <p>
              Once you move past scenario planning, you will be engaged in live proposal exchange
              and counter proposals with your counterparty. There isn’t a prescribed or typical
              length of negotiation or standard count for how many counter proposals are necessary.
              What is best-practice is having a means of knowing exactly where the current position
              of either party stands across the variables being discussed in your deal.
            </p>
            <p>
              The Deal Tracker is a tool that allows you to track both party’s positions throughout
              the negotiation. Modern day negotiations typically aren’t settled in one single
              interaction. In fact, in our experience it is exceedingly rare that more than a few
              proposals are exchanged in a single interaction. It is important that you have a way
              to track proposals as they are made through various interactions such as in-person
              meetings, virtual meetings, phone calls or email exchanges.
            </p>
            <p>
              This is helpful for several reasons. First, it ensures you do not lose momentum of the
              progress being made through your negotiation. Second, it serves as a progress
              diagnostic of the negotiation, helping you identify areas of the deal where you have
              alignment and variables that still need to be aligned. Third, it becomes institutional
              knowledge for your subsequent negotiations with that party. Having a ‘play by play’
              history of how your negotiations played out can inform future interactions with that
              same party. Lastly, it allows you to tell the story internally of where the deal
              stands and update relevant parties on progress.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>
              You begin the Negotiate phase by creating “Scenarios” at the top of the page. To do
              this, click on the “Add Scenarios” button. You will now see three columns appear, one
              for “Scenario A”, “Scenario B”, and “Scenario C”.
            </p>
            <p>
              The purpose of the Scenarios build is to provide three possible end states for the
              negotiation. IMPORTANTLY, scenarios are not offers that you plan on making, but rather
              they are possible end states. What could this deal look like in the end once all the
              negotiations are over.
            </p>
            <p>
              Your Scenario A should be your best case scenario end state, with B being a compromise
              and C being your worst case but still acceptable outcome.
            </p>
            <p>
              All the variables that you identified in the Variables phase will be present down the
              left hand column, simply select the position of the variable in each cell for each
              scenario.
            </p>
            <p>
              You can also assign a value to each variable in each scenario, giving a fuller picture
              of the overall value proposition of the totality of the end state scenario. Once
              Scenarios are built, you can now begin building offers.
            </p>
            <p>Use the + sign beside “Deal Tracker”.</p>
            <p>
              This will launch a popup window where you will see all your variables on the left hand
              side, organized by Gets on the top, Get/Gives in the middle and Gives on the bottom of
              the list.
            </p>
            <p>
              To create an offer, simply drag and drop any variable into the right hand side columns
              of GETS or GIVES. Remembering that GETS are the things we want in the deal, and GIVES
              are the things they want in the deal.
            </p>
            <p>
              Using the ‘conditional trading’ framework of “On the condition that…(get), we could
              agree too…(give), create a proposal by dragging and dropping the variables into the
              respective columns.
            </p>
            <p>
              Once a variable has been moved into either the GETS or GIVES column, you will have to
              choose a position for that variable that you want to offer in the proposal.
            </p>
            <p>
              You can choose from one of your preselected thresholds using the dropdown menu under
              each variable, or create a custom input by using the “Other” option in the drop down
              menu.
            </p>
            <p>
              NOTE, not all variables need to be included in every offer. You can build an offer
              with as many or as few variables as you choose.
            </p>
            <p>
              Once you’ve included all the variables that you want to include in an offer, click on
              the “Make Offer” button in the bottom right of the popup screen.
            </p>
            <p>
              Your offer will now automatically appear in the “Offer 1 - Us” column of the deal
              tracker.
            </p>
            <p>
              If/when your counterparty responds or counteroffers your proposal, you can add their
              offer into the “Them” column of Offer 1.
            </p>
            <p>
              Once you reach alignment on any variable and the values in both the Us and Them
              columns match, the variable will automatically become “aligned” and ported over to the
              left most column of the deal tracker under the “Aligned” column.
            </p>
            <p>
              You can repeat the above process for creating proposals as many times as necessary in
              order to reach alignment on all variables to close down the negotiation.
            </p>
          </>
        ),
      },
      {
        title: Titles.BestPractices,
        content: (
          <>
            <p>
              Be open-minded and creative in planning scenarios. There are often many paths that can
              help you meet or exceed your objectives so do not get too focused on one specific
              variable or type of deal—play with different combinations of variables to see what
              might be possible.
            </p>
            <p>
              Remember that scenarios are just that. You are not bound to having to live into any
              specific scenario. They are for planning but do not need to be adhered to rigidly as
              the negotiation plays out.
            </p>
            <p>
              During your negotiation, look to repackage the variables in a way that achieves your
              objectives while also responding to input and feedback from your counterparty—the best
              way to beat a proposal is to make one.
            </p>
            <p>
              Any number and combination of variables can be included in any proposal. There is no
              right and/or wrong way to make a proposal, however, try to ensure that there is always
              a GET and GIVE in each of your proposals ensuring there is value being returned to you
              when value is being given up.
            </p>
            <p>
              If you don’t like what the other party is proposing, make your own counter proposal.
              Always try to steer the conversation back around to your position, and don’t dwell on
              theirs for too long.
            </p>
            <p>
              Avoid the temptation of arguing over the validity of yours or their proposal. You will
              rarely if ever change someone else’s mind and convince them that your position is
              correct and theirs is incorrect. Simply talk your numbers/proposal and always steer
              the conversation back to your offer.
            </p>
            <p>
              Nothing is final until you have agreed on the entirety of the deal. Use the word
              ‘align’ or conditional language when aligning on single variables. You may need to
              revisit them as the deal progresses so avoid the term ‘agree’ as it tends to connote
              finality.
            </p>
          </>
        ),
      },
    ],
  },
  {
    tab: ProjectTabs.align,
    video: <VideoEmbed videoId={VideoIds.Align} sx={{ my: 2 }} />,
    title: 'Align Help',
    sections: [
      {
        title: Titles.Why,
        content: (
          <>
            <p>
              An often overlooked, yet essential phase of the negotiation process is the Align
              phase. Your efforts in this phase should be focused on external and internal
              considerations.
            </p>
            <p>
              Externally, this step is about ensuring you take necessary actions for the deal to be
              implemented as agreed. Getting the proverbial handshake is just the first step of
              living into the agreed deal. Document any execution or implementation steps required
              to make sure the deal gets ‘actioned’ as agreed.
            </p>
            <p>
              Internally, this step should focus on two areas. The first is ensuring the deal is
              communicated to relevant parties and you ensure the executional elements are clear and
              next steps are defined. Once you move past the executional components of the deal, the
              final step of the Align phase is evaluating the negotiation for continuous
              improvement.
            </p>
            <p>
              Taking a moment to reflect on the performance of you and your negotiating team can be
              a critical step to identify best-practice areas of strength and areas for improvement.
              While it is easy to skip this step as you go chase the next deal, taking the time to
              do this right is how you build a negotiation culture of excellence over time.
            </p>
          </>
        ),
      },
      {
        title: Titles.WhatHow,
        content: (
          <>
            <p>Begin by filling out the “Compliance” section of the plan. </p>
            <p>
              This includes “Who” needs to be involved, “What” specifically needs to happen, “When”
              the event needs to occur, and whether or not it is a recurring event or a one off. And
              lastly, what is the “status” of the event.
            </p>
            <p>You can add additional compliance events by using the “ADD NEW” button below. </p>
            <p>Once compliance is completed, it’s now time to do a ‘post-mortem’ on your deal.</p>
            <p>
              Click on the “GET STARTED” button in the gold banner, and answer the series of
              questions to the best of your ability.
            </p>
            <p>
              This information capture is key for building long term skills and institutional
              knowledge around customers and negotiation, capturing what went well and what could
              have been done better for future reference.
            </p>
          </>
        ),
      },
    ],
  },
]
