import { AppBar, Box, Slide, Stack, useScrollTrigger } from '@mui/material'
import { useSession } from 'next-auth/react'

import ChatPanel from 'components/chat/ChatPanel'
import NotificationPanel from 'components/notifications/NotificationPanel'
import { useProject } from 'components/projects/ProjectProvider'
import SearchForm from 'components/search/form/SearchForm'
import { useSearch } from 'components/search/SearchProvider'
import TutorialDrawer from 'components/tutorial/TutorialDrawer'

import ToggleCursorButton from '../live-presence/ToggleCursorButton'

import HomeButton from './nav/HomeButton'
import LogoutButton from './nav/LogoutButton'
import ProfileButton from './nav/ProfileButton'
import ReturnToYourAccount from './nav/ReturnToYourAccount'
import SiteToolbar from './SiteToolbar'

export default function AppHeader() {
  const projectContext = useProject()
  const searchContext = useSearch()
  const trigger = useScrollTrigger()

  const session = useSession()
  // used for login as
  const prevToken = session?.data?.prevToken

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar elevation={0} sx={{ zIndex: (theme) => theme.zIndex.appBar }}>
        <SiteToolbar>
          <Box sx={{ minWidth: '220px' }}>
            <HomeButton />
          </Box>
          {searchContext && (
            <Box sx={{ flex: 1, px: { xs: 2, sm: 5 } }}>
              <SearchForm />
            </Box>
          )}
          {/* Desktop */}
          <Stack
            sx={{ display: { xs: 'none', sm: 'flex' } }}
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end">
            <>
              {prevToken && <ReturnToYourAccount />}
              {searchContext && <ToggleCursorButton />}
              {!prevToken && <LogoutButton />}
              <TutorialDrawer />
              <ProfileButton />
              {searchContext && <NotificationPanel />}
              {/* `projectContext` is defined only when ProjectProvider context is available */}
              {projectContext && <ChatPanel />}
            </>
          </Stack>
          {/* Mobile */}
          <Stack sx={{ display: { xs: 'block', sm: 'none' } }}>
            <LogoutButton />
          </Stack>
        </SiteToolbar>
      </AppBar>
    </Slide>
  )
}
