import { createTheme, inputClasses, Theme } from '@mui/material'

import { colors } from './colors'

export const darkTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      mode: 'dark',
      primary: {
        main: colors.bgDarkTextLight,
        light: colors.bgDarkText,
      },
      text: {
        primary: colors.white,
        secondary: colors.bgDarkTextLight,
      },
      divider: colors.greyDark,
    },
    components: {
      ...theme.components,
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '1rem',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            [`.${inputClasses.root}::before`]: { display: 'none' },
            [`.${inputClasses.root}::after`]: { display: 'none' },
            '& svg': {
              color: colors.bgDarkText,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: colors.backgroundLight,
            color: colors.text,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: colors.bgDarkText,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: colors.greyLight,
            color: colors.greyDark,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: colors.background,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '::before': {
              display: 'none',
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            color: colors.bgDarkText,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            color: colors.bgDarkTextLight,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: colors.borderDark,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: colors.backgroundDark,
            backgroundImage: 'none',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardInfo: {
            color: colors.white,
            background: colors.blue,
          },
          root: {
            '& .MuiAlert-icon': {
              color: colors.white,
            },
          },
        },
      },
    },
  })
