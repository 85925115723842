import { Toolbar } from '@mui/material'
import { ReactNode } from 'react'

import { themeMinHeight } from 'theme/theme'

type Props = { children?: ReactNode }

export default function SiteToolbar({ children }: Props) {
  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        // Override media queries injected by theme.mixins.toolbar
        '@media all': {
          minHeight: { xs: themeMinHeight.siteToolbar.xs, sm: themeMinHeight.siteToolbar.sm },
        },
      }}>
      {children}
    </Toolbar>
  )
}
