import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import { Badge, BadgeProps, IconButton, IconButtonProps, Tooltip } from '@mui/material'

import { badgeStyles, buttonStyles } from './navStyles'

type Props = IconButtonProps & {
  badgeProps: BadgeProps
}
export default function NotificationsButton({ badgeProps, ...props }: Props) {
  return (
    <Tooltip title="Notifications">
      <IconButton {...props} sx={buttonStyles}>
        <Badge
          variant="standard"
          componentsProps={{ badge: { style: badgeStyles } }}
          {...badgeProps}>
          <NotificationsNoneOutlinedIcon sx={{ fontSize: '1.5rem' }} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
