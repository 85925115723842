import Avatar, { AvatarProps } from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'

import { useUser } from 'providers/UserProvider'
import { getInitials } from 'utils/helpers'

type Props = AvatarProps & {
  size?: number
  fontSize?: number
}

export default function CurrentUserAvatar({ size = 23, fontSize = 8, ...props }: Props) {
  const { user } = useUser()
  if (!user) return null
  return (
    <Tooltip title={`${user.fullname} (${user.email})`}>
      <Avatar
        alt={user.fullname}
        src={user.avatarUrl ?? undefined}
        {...props}
        sx={{
          fontSize,
          width: size,
          height: size,
          color: 'bg.light',
          backgroundColor: 'secondary.main',
          border: '2px solid transparent',
          transition: '.3s',
        }}>
        {user.avatarUrl ? undefined : user?.fullname ? getInitials(user.fullname) : undefined}
      </Avatar>
    </Tooltip>
  )
}
