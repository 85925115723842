import { Box } from '@mui/material'

import useMembership from 'hooks/useMembership'
import { colors } from 'theme/colors'
import { internalShadows } from 'theme/shadows'

import ChatTextField from './ChatTextField'

export default function NewCommentField() {
  const { canEdit } = useMembership()
  return (
    <Box
      sx={{
        p: 2,
        top: 0,
        zIndex: 1,
        position: 'sticky',
        bgcolor: 'bg.dark',
        color: colors.bgDarkText,
        boxShadow: internalShadows.popover,
        mb: '-1px', // compensate for double border
        borderBottom: `1px solid ${colors.borderDark}`,
      }}>
      <ChatTextField readOnly={!canEdit} placeholder="Make a comment..." />
    </Box>
  )
}
