import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import { alpha, styled } from '@mui/material/styles'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import CustomSettingsIcon from 'assets/CustomSettingsIcon'

import SearchInput from '../fields/SearchInput'
import { SearchParams, useSearch } from '../SearchProvider'

import AdvancedSearchForm from './AdvancedSearchForm'

const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: 630,
  borderRadius: 11,
  transition: 'all .2s',
  padding: theme.spacing(0.75, 0),
  [theme.breakpoints.only('xs')]: { padding: theme.spacing(0.25, 0) },
  color: theme.palette.primary.light,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  pointerEvents: 'none',
  padding: theme.spacing(0, 2),
}))

export default function SearchForm() {
  const searchRef = useRef<HTMLDivElement>(null)
  const { searchParams, triggerSearch } = useSearch()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleOpen = () => setAnchorEl(searchRef.current)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)
  const methods = useForm<SearchParams>({
    mode: 'onSubmit',
    defaultValues: searchParams,
  })

  const { handleSubmit, reset } = methods

  // Sync form with search context
  useEffect(() => {
    reset(searchParams)
  }, [reset, searchParams])

  const onSubmit = handleSubmit(async (values) => {
    triggerSearch(values)
    handleClose()
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <SearchBox ref={searchRef}>
          <SearchIconWrapper>
            <SearchIcon fontSize="large" />
          </SearchIconWrapper>
          <SearchInput />
          <Button onClick={handleOpen}>
            <CustomSettingsIcon fontSize="large" />
          </Button>
          <Popover
            open={open}
            disablePortal
            anchorEl={anchorEl}
            onClose={handleClose}
            id="open-settings-popover"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            slotProps={{
              paper: {
                square: true,
                elevation: 5,
                sx: { minWidth: 590, px: 2.5, pt: 2.5, pb: 1.5, mt: -0.5 },
              },
            }}>
            <AdvancedSearchForm />
          </Popover>
        </SearchBox>
      </form>
    </FormProvider>
  )
}
