import * as Types from '../types';

import { gql } from '@apollo/client';
import { CommentFieldsFragmentDoc } from './CommentFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommentsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['UUID'];
  orderBy?: Types.InputMaybe<Array<Types.ProjectCommentsOrderBy> | Types.ProjectCommentsOrderBy>;
  includeArchived?: Types.InputMaybe<Types.IncludeArchivedOption>;
}>;


export type CommentsQuery = { __typename?: 'Query', projectComments: { __typename?: 'ProjectCommentsConnection', nodes: Array<{ __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, replies: { __typename?: 'ProjectCommentsConnection', nodes: Array<{ __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null }> }, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null }> } | null };


export const CommentsDocument = gql`
    query Comments($projectId: UUID!, $orderBy: [ProjectCommentsOrderBy!] = CREATED_AT_DESC, $includeArchived: IncludeArchivedOption = NO) {
  projectComments(
    filter: {projectId: {equalTo: $projectId}, parentId: {isNull: true}}
    includeArchived: $includeArchived
    orderBy: $orderBy
  ) {
    nodes {
      ...CommentFields
      replies: childProjectComments(orderBy: CREATED_AT_ASC) {
        nodes {
          ...CommentFields
        }
      }
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      orderBy: // value for 'orderBy'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;