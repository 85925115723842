import * as Types from '../types';

import { gql } from '@apollo/client';
import { CommentFieldsFragmentDoc } from './CommentFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LiveCommentsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['UUID'];
}>;


export type LiveCommentsSubscription = { __typename?: 'Subscription', projectCommentsUpdated: { __typename?: 'ProjectCommentSubscriptionPayload', projectComment: { __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, replies: { __typename?: 'ProjectCommentsConnection', nodes: Array<{ __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null }> }, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null } | null } | null };


export const LiveCommentsDocument = gql`
    subscription LiveComments($projectId: UUID!) {
  projectCommentsUpdated(projectId: $projectId) {
    projectComment {
      ...CommentFields
      replies: childProjectComments {
        nodes {
          ...CommentFields
        }
      }
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

/**
 * __useLiveCommentsSubscription__
 *
 * To run a query within a React component, call `useLiveCommentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLiveCommentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveCommentsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useLiveCommentsSubscription(baseOptions: Apollo.SubscriptionHookOptions<LiveCommentsSubscription, LiveCommentsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LiveCommentsSubscription, LiveCommentsSubscriptionVariables>(LiveCommentsDocument, options);
      }
export type LiveCommentsSubscriptionHookResult = ReturnType<typeof useLiveCommentsSubscription>;
export type LiveCommentsSubscriptionResult = Apollo.SubscriptionResult<LiveCommentsSubscription>;