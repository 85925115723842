import { IconButton } from '@mui/material'
import NextLink from 'next/link'

import { routes } from 'routes'

import CurrentUserAvatar from 'components/CurrentUserAvatar'

export default function ProfileButton() {
  return (
    <NextLink href={routes.profile} passHref>
      <IconButton>
        <CurrentUserAvatar size={22} fontSize={14} />
      </IconButton>
    </NextLink>
  )
}
