import * as Types from '../types';

import { gql } from '@apollo/client';
export type TeamFieldsFragment = { __typename?: 'Team', id: string, name: string, accountId: string, parentTeamId: string | null, hierarchyPath: Array<{ __typename?: 'TeamHierarchyInfo', id: string | null, name: string | null } | null>, projects: { __typename?: 'ProjectsConnection', totalCount: number } };

export const TeamFieldsFragmentDoc = gql`
    fragment TeamFields on Team {
  id
  name
  accountId
  parentTeamId
  hierarchyPath {
    id
    name
  }
  projects {
    totalCount
  }
}
    `;