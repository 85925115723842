import { Theme, useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { ReactNode, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Preloader from 'components/Preloader'

import HiddenInput from './HiddenInput'

export type AutoCompleteOptionType = {
  label: string
  value: string
  disabled?: boolean
}
export type Props = {
  icon?: ReactNode
  name: string
  options: AutoCompleteOptionType[]
  dense?: boolean
  direction?: 'row' | 'column'
  disableClearable?: boolean
  getValueLabel?: boolean
  loading?: boolean
} & TextFieldProps

export default function ControlLabelAutocomplete({
  icon,
  name,
  label,
  dense,
  options,
  direction = 'row',
  disableClearable = false,
  getValueLabel = false,
  loading,
  ...props
}: Props) {
  const theme = useTheme<Theme>()
  const { control, setValue, watch } = useFormContext()
  const currentItem = watch(name)
  useEffect(() => {
    // if we don't want to get the value label, return
    if (!getValueLabel) return
    // if we don't have a value, set the label to empty
    if (!currentItem) return setValue(`${name}Label`, '')
    const value = options.find((option) => option.value === currentItem)?.label ?? ''
    setValue(`${name}Label`, value)
  }, [currentItem, getValueLabel, name, options, setValue])

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Stack gap={icon ? 1.4 : 0} direction={direction} alignItems="center" sx={{ mb: 1 }}>
              {icon && icon}
              {label && (
                <InputLabel
                  htmlFor={name}
                  sx={{
                    pointerEvents: props.disabled ? 'none' : undefined,
                    paddingY: 1,
                    minWidth: dense
                      ? theme.formFieldLabelWidth.dense
                      : theme.formFieldLabelWidth.default,
                  }}>
                  {label}
                </InputLabel>
              )}
              {loading ? (
                <Preloader isInput />
              ) : (
                <Autocomplete
                  fullWidth
                  openOnFocus
                  size="small"
                  value={value}
                  id={name}
                  options={options}
                  autoComplete={false}
                  disabled={props.disabled}
                  disableClearable={disableClearable}
                  onChange={(e, data) => {
                    onChange(data?.value ?? '')
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return options.find(({ value }) => value === option)?.label ?? ''
                    }
                    return options.find(({ value }) => value === option.value)?.label ?? ''
                  }}
                  getOptionDisabled={(option) => option.disabled ?? false}
                  // we set `key` to `value` to allow multiple items with same label
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      color="secondary"
                      variant="outlined"
                      error={!!error}
                      helperText={error ? error?.message : null}
                      {...params}
                      {...props}
                    />
                  )}
                />
              )}
            </Stack>
          )
        }}
      />
      {/* We store the label value in a hidden input so we can use it for other conditions in the form */}
      {getValueLabel && (
        <Controller
          name={`${name}Label`}
          control={control}
          render={({ field: { value } }) => {
            return <HiddenInput name={`${name}Label`} value={value} />
          }}
        />
      )}
    </>
  )
}
