import Stack from '@mui/material/Stack'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { userIsConsultant } from 'roles'

import CancelButton from 'components/CancelButton'
import AccountSelect from 'components/form/AccountSelect'
import ControlLabelSwitch from 'components/form/ControlLabelSwitch'
import ControlLabelTextField from 'components/form/ControlLabelTextField'
import FieldStack from 'components/form/FieldStack'
import SubmitFormButton from 'components/SubmitFormButton'
import { useUser } from 'providers/UserProvider'
import { ALL_FILTER_VALUE } from 'utils/constants'

import DateModifiedSelect from '../fields/DateModifiedSelect'
import ProjectValueSelect from '../fields/ProjectValueSelect'
import SearchPersonAutocomplete from '../fields/SearchPersonAutocomplete'
import { SearchParams, useSearch } from '../SearchProvider'

export default function AdvancedSearchForm() {
  const { user, accountId } = useUser()
  const { resetSearch } = useSearch()
  const searchAccountId = useWatch({ name: 'accountId' })
  const { watch, setValue } = useFormContext<SearchParams>()

  const defaultOrganizationIdValue = userIsConsultant(user) ? ALL_FILTER_VALUE : accountId

  useEffect(() => {
    // Sync back the form to the context
    const subscription = watch(async (value, { name }) => {
      // Reset owner and sharedWith when accountId is changed
      if (name === 'accountId') {
        setValue('owner', '')
        setValue('sharedWith', [])
      }
      // Reset accountId when isOpen is changed
      if (name === 'isOpen') {
        setValue('accountId', defaultOrganizationIdValue)
      }
    })
    return () => subscription.unsubscribe()
  }, [defaultOrganizationIdValue, setValue, watch])

  return (
    <FieldStack spacing={1.25} sx={{ maxWidth: 'sm' }}>
      <ControlLabelTextField
        fullWidth
        name="name"
        label="Name"
        id="name-search"
        placeholder="Enter a term that appears in the file name"
      />
      <ControlLabelTextField
        fullWidth
        name="keywords"
        label="Keywords"
        id="keyword-search"
        placeholder="Words found in the project"
      />
      <DateModifiedSelect />
      <ProjectValueSelect />
      {userIsConsultant(user) && (
        <ControlLabelSwitch name="isOpen" label="Open Project" maxWidth="200px" />
      )}
      {userIsConsultant(user) && !watch('isOpen') && (
        <AccountSelect name="accountId" label="Organization" showAll />
      )}
      <SearchPersonAutocomplete name="owner" label="Owner" accountId={searchAccountId} />
      <SearchPersonAutocomplete
        name="sharedWith"
        label="Shared to"
        multiple
        accountId={searchAccountId}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={1} pt={1}>
        <CancelButton label="Reset" onClick={() => resetSearch()} />
        <SubmitFormButton label="Search" />
      </Stack>
    </FieldStack>
  )
}
