import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { useRouter } from 'next/router'
import { MouseEvent, useEffect, useState } from 'react'

import { baseProjectsRoute, routes } from 'routes'

import StyledDrawer from 'components/drawer/StyledDrawer'
import FaqButton from 'components/layouts/nav/FaqButton'
import { useProject } from 'components/projects/ProjectProvider'
import { colors } from 'theme/colors'
import { internalShadows } from 'theme/shadows'

import { tutorial, TutorialTab } from './tutorialContent'

type TutorialTabsState = {
  [key in TutorialTab]: boolean
}

export default function TutorialDrawer() {
  const router = useRouter()
  const projectContext = useProject()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget))
  }
  const handleClose = () => setAnchorEl(null)
  const [tabState, setTabState] = useState<TutorialTabsState>({
    dashboard: false,
    questions: false,
    objectives: false,
    power: false,
    variables: false,
    communications: false,
    time: false,
    risk: false,
    negotiate: false,
    align: false,
    introduction: false,
  })

  const handleChange = (tab: TutorialTab, isExpanded: boolean) => {
    setTabState((oldState: TutorialTabsState) => {
      // close all other tabs
      const resetState = Object.keys(oldState).reduce((state, key) => {
        state[key as keyof TutorialTabsState] = false
        return state
      }, {} as TutorialTabsState)

      return { ...resetState, [tab]: isExpanded }
    })

    if (projectContext && tab && tab !== 'introduction') {
      const { projectId } = projectContext
      router.push(routes.npt.projectById(projectId, tab))
    }
  }

  useEffect(() => {
    const isActiveByPath = (tab: TutorialTab) => {
      if (tab == 'introduction') return false

      return router.pathname === `${baseProjectsRoute}/${tab}`
    }

    setTabState((oldState: TutorialTabsState) => {
      const resetState = Object.keys(oldState).reduce((state, key) => {
        state[key as keyof TutorialTabsState] = isActiveByPath(key as TutorialTab)
        return state
      }, {} as TutorialTabsState)

      return { ...resetState }
    })
  }, [router.pathname])

  return (
    <>
      <FaqButton onClick={handleClick} />
      {anchorEl && (
        <StyledDrawer drawerWidth="33vw" onClose={handleClose} open={Boolean(anchorEl)}>
          <Stack direction="column" sx={{ px: 3, pt: 3 }} spacing={1}>
            {tutorial.map((tutorialItem, index) => {
              const tabIsActive = tabState[tutorialItem.tab]

              return (
                <Accordion
                  key={index}
                  expanded={tabState[tutorialItem.tab]}
                  onChange={(event: React.SyntheticEvent, isExpanded: boolean) =>
                    handleChange(tutorialItem.tab, isExpanded)
                  }
                  sx={{ bgcolor: 'inherit' }}>
                  <AccordionSummary
                    id={`${tutorialItem}-header`}
                    sx={{
                      borderLeft: tabIsActive
                        ? `2px solid ${colors.yellow}`
                        : '2px solid transparent',
                      position: 'sticky',
                      top: '0',
                      color: tabIsActive ? 'white' : undefined,
                      bgcolor: 'bg.dark',
                      backgroundImage:
                        'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                      boxShadow: internalShadows.accordion,
                      zIndex: 999,
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${tutorialItem}-content`}>
                    <Typography variant="h4">{tutorialItem.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {tutorialItem?.video}
                    <Stack direction="column">
                      {tutorialItem?.sections?.map((section, sectionIdx) => (
                        <Box key={sectionIdx} sx={{ fontSize: 14, fontWeight: 300 }}>
                          <Typography variant="h5" py={2} fontWeight={600}>
                            {section.title}
                          </Typography>
                          {section.content}
                        </Box>
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Stack>
        </StyledDrawer>
      )}
    </>
  )
}
