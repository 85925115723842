import NearMeIcon from '@mui/icons-material/NearMe'
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled'
import { IconButton, Tooltip } from '@mui/material'
import { useLocalStorage } from 'usehooks-ts'

import { buttonStyles } from 'components/layouts/nav/navStyles'
import useGTM, { GtmEvent } from 'hooks/useGTM'

export default function ToggleCursorButton() {
  const { trackEvent } = useGTM()
  const [visible, setVisible] = useLocalStorage('visible-cursors', true)
  const title = `${visible ? 'Hide' : 'Show'} collaborators' cursors`

  const clickHandler = () => {
    setVisible((prev) => {
      trackEvent({
        event: prev ? GtmEvent.HideCollboratorCursors : GtmEvent.ShowCollboratorCursors,
      })
      return !prev
    })
  }
  return (
    <Tooltip title={title}>
      <IconButton sx={buttonStyles} onClick={clickHandler}>
        {visible ? (
          <NearMeIcon sx={{ transform: 'rotateY(180deg)' }} />
        ) : (
          <NearMeDisabledIcon sx={{ transform: 'rotateY(180deg)' }} />
        )}
      </IconButton>
    </Tooltip>
  )
}
