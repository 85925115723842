import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import MarkChatRead from '@mui/icons-material/MarkChatRead'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { MouseEvent, useCallback, useState } from 'react'

import { useUpdateCommentMutation } from 'graphql/comment/UpdateComment.gen'

import { colors } from 'theme/colors'

type Props = {
  isOwn: boolean
  commentId: string
  isThread: boolean
  isResolved: boolean
  onEdit: () => void
}

export default function CommentMenu({ commentId, isOwn, isResolved, isThread, onEdit }: Props) {
  const [updateComment] = useUpdateCommentMutation()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget))
  }
  const closeMenu = useCallback(() => setAnchorEl(null), [])

  const handleEdit = useCallback(async () => {
    onEdit()
    closeMenu()
  }, [closeMenu, onEdit])

  const handleResolve = useCallback(async () => {
    await updateComment({
      variables: {
        input: { id: commentId, patch: { isArchived: true } },
      },
      refetchQueries: ['Comments'],
    })
    closeMenu()
  }, [commentId, closeMenu, updateComment])

  const handleUnResolve = useCallback(async () => {
    await updateComment({
      variables: {
        input: { id: commentId, patch: { isArchived: false } },
      },
      refetchQueries: ['Comments'],
    })
    closeMenu()
  }, [commentId, closeMenu, updateComment])

  const handleDelete = useCallback(async () => {
    await updateComment({
      variables: {
        input: { id: commentId, patch: { isDeleted: true } },
      },
      refetchQueries: ['Comments'],
    })
    closeMenu()
  }, [commentId, closeMenu, updateComment])

  if (
    // Hide comment menu for replies of resolved threads
    (isResolved && !isThread) ||
    // Hide comment menu for replies not owned by me
    (!isOwn && !isThread)
  ) {
    return null
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHoriz color="inherit" />
      </IconButton>
      <Menu
        elevation={3}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        PaperProps={{ square: true, sx: { width: 160 } }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
        {isThread && !isResolved && (
          <MenuItem onClick={handleResolve}>
            <ListItemIcon>
              <MarkChatRead fontSize="small" sx={{ color: colors.textLight }} />
            </ListItemIcon>
            <ListItemText>Resolve</ListItemText>
          </MenuItem>
        )}
        {isThread && isResolved && (
          <MenuItem onClick={handleUnResolve}>
            <ListItemIcon>
              <SmsOutlinedIcon fontSize="small" sx={{ color: colors.textLight }} />
            </ListItemIcon>
            <ListItemText>Unresolve</ListItemText>
          </MenuItem>
        )}
        {isOwn && !isResolved && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <Edit fontSize="small" sx={{ color: colors.textLight }} />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {isOwn && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <Delete fontSize="small" sx={{ color: colors.textLight }} />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
