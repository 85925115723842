import { TabContext } from '@mui/lab'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useCommentsQuery } from 'graphql/comment/Comments.gen'
import { useLiveCommentsSubscription } from 'graphql/comment/LiveComments.gen'
import { IncludeArchivedOption } from 'graphql/types'

import { useProject } from 'components/projects/ProjectProvider'

import ChatDrawer from './ChatDrawer'
import { ChatTabOption } from './ChatTabs'

export default function ChatPanel() {
  const { projectId } = useProject()
  const [includeArchived, setIncludeArchived] = useState(IncludeArchivedOption.No)
  const { data, refetch } = useCommentsQuery({ variables: { projectId, includeArchived } })
  const { data: wsData } = useLiveCommentsSubscription({ variables: { projectId } })

  // Update query when ws comment is added/updated/archived
  useEffect(() => {
    refetch({ includeArchived })
  }, [includeArchived, refetch, wsData])

  const tab = useMemo(() => {
    if (includeArchived === IncludeArchivedOption.Exclusively) {
      return 'resolved'
    }
    return 'all'
  }, [includeArchived])

  const onTabChange = useCallback((tab: ChatTabOption) => {
    setIncludeArchived(tab === 'all' ? IncludeArchivedOption.No : IncludeArchivedOption.Exclusively)
  }, [])

  return (
    <TabContext value={tab}>
      <ChatDrawer onTabChange={onTabChange} items={data?.projectComments?.nodes ?? []} />
    </TabContext>
  )
}
