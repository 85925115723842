import InfoIcon from '@mui/icons-material/Info'
import {
  Checkbox,
  Divider,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FixedSizeList as List } from 'react-window'

import { colors } from 'theme/colors'

export type ControlSelectMultipleCheckboxOption = {
  label: string
  value: string
  disabled?: boolean
  hideCheckbox?: boolean
  sx?: SxProps
}
export type ControlSelectMultipleCheckboxProps = {
  icon?: React.ReactNode
  name: string
  label?: string
  dense?: boolean
  disabled?: boolean
  multiple?: boolean
  placeholder?: string
  placeholderValue?: string
  options: ControlSelectMultipleCheckboxOption[]
  children?: ReactNode
  currentSelected?: string[]
  tooltip?: string
  menuProps?: SelectProps['MenuProps']
}

export default function ControlSelectMultipleCheckbox({
  icon,
  name,
  label,
  disabled,
  dense = false,
  options,
  placeholder = 'Select',
  tooltip,
  menuProps,
}: ControlSelectMultipleCheckboxProps) {
  const theme = useTheme<Theme>()
  const [search, setSearch] = useState('')
  const { control, getValues, setValue } = useFormContext()
  const [internalValues, setInternalValues] = useState<string[]>([])

  const labels = useMemo(() => {
    return options.reduce<Record<string, ReactNode | string>>((acc, cur) => {
      acc[cur.value] = cur.label
      return acc
    }, {})
  }, [options])

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        labels[option.value]?.toString().toLowerCase().includes(search.toLowerCase())
      ),
    [labels, options, search]
  )

  const selectedOptions: string[] = getValues()[name]
  const shouldSelect = useMemo(
    () => selectedOptions.length !== filteredOptions.length,
    [filteredOptions.length, selectedOptions.length]
  )

  const handleItemSelect = useCallback(
    (value: string) => {
      if (internalValues.includes(value)) {
        setValue(
          name,
          internalValues.filter((x) => x !== value),
          {
            shouldDirty: true,
          }
        )
        setInternalValues((state: string[]) => state.filter((x) => x !== value))
      } else {
        setValue(name, [...internalValues, value], {
          shouldDirty: true,
        })
        setInternalValues((state) => [...state, value])
      }
    },
    [internalValues, name, setValue]
  )

  useEffect(() => {
    setInternalValues(selectedOptions || [])
  }, [selectedOptions])

  const itemData = useMemo(
    () => ({
      filteredOptions,
      internalValues,
      handleItemSelect,
      name,
    }),
    [filteredOptions, internalValues, handleItemSelect, name]
  )

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const option = filteredOptions[index]
    const hiddenCheckboxIsChecked = option.hideCheckbox && internalValues.includes(option.value)
    return (
      <MenuItem
        key={option.value}
        value={option.value}
        disabled={option.disabled}
        disableGutters
        sx={{
          p: 0,
          '&.Mui-disabled': {
            opacity:
              option.disabled && (!option.hideCheckbox || hiddenCheckboxIsChecked) ? '0.38' : '1',
          },
          ...option.sx,
        }}
        onClick={() => handleItemSelect(option.value)}
        style={style}>
        {(!option.hideCheckbox || hiddenCheckboxIsChecked) && (
          <Checkbox
            id={`multiple-select-item-${name}-${option.value}`}
            color="secondary"
            checked={internalValues.includes(option.value)}
            disabled={disabled}
          />
        )}
        <ListItemText
          primary={option.label}
          color={disabled && !option.hideCheckbox ? 'text.disabled' : 'text.primary'}
        />
      </MenuItem>
    )
  }

  // Select all available options
  const handleSelectAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const enabledOptions = filteredOptions.filter((opt) => !opt.disabled)
    const newValues = shouldSelect
      ? [...new Set([...internalValues, ...enabledOptions.map((o) => o.value)])]
      : internalValues.filter(
          (value) => filteredOptions.find((o) => o.value === value)?.disabled ?? false
        )

    setValue(name, newValues, {
      shouldDirty: true,
    })
    setInternalValues(newValues)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Stack direction="row">
            {label && (
              <InputLabel
                htmlFor={name}
                sx={{
                  minWidth: dense
                    ? theme.formFieldLabelWidth.dense
                    : theme.formFieldLabelWidth.default,
                  paddingY: 1,
                  gap: 1.4,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {icon && icon}
                {label}
                {tooltip && (
                  <Tooltip title={tooltip} sx={{ alignContent: 'center' }}>
                    <InfoIcon
                      sx={{ color: 'primary.light', width: '16px', height: '16px', ml: 1 }}
                    />
                  </Tooltip>
                )}
              </InputLabel>
            )}
            <Select
              labelId={`multiple-select-${name}`}
              id={`multiple-select-${name}`}
              disabled={disabled}
              multiple
              {...field}
              size="small"
              autoComplete="off"
              placeholder={placeholder}
              sx={{
                width: '1px', //hacky way to limit width
                flex: 1,
                '& .MuiSelect-multiple::before':
                  selectedOptions.length === 0
                    ? {
                        content: `"${placeholder}"`,
                        color: disabled ? colors.textPlaceholderDisabled : colors.textPlaceholder,
                      }
                    : {},
              }}
              MenuProps={{
                MenuListProps: {
                  sx: { p: 1 },
                },
                ...menuProps,
              }}
              renderValue={(selected) => selected.map((item: string) => labels[item]).join(', ')}>
              <li tabIndex={-1}>
                <TextField
                  autoFocus
                  sx={{ pl: 1.4, width: '90%' }}
                  value={search}
                  variant="standard"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  // Disable item selection on default select search
                  onKeyDownCapture={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                />
              </li>
              {filteredOptions.length > 0 && (
                <List
                  height={200}
                  itemSize={40}
                  itemCount={filteredOptions.length}
                  width="100%"
                  itemData={itemData}>
                  {Row}
                </List>
              )}
              {filteredOptions.length === 0 && <MenuItem disabled>No results</MenuItem>}
              <Divider />
              {filteredOptions.length > 0 && (
                <li>
                  <Typography sx={{ pl: 2, pt: 1, cursor: 'pointer' }} onClick={handleSelectAll}>
                    {shouldSelect ? 'Select all' : 'Deselect all'}
                  </Typography>
                </li>
              )}
            </Select>
          </Stack>
          {error && <FormHelperText error={!!error}>{error ? error.message : null}</FormHelperText>}
        </>
      )}
    />
  )
}
