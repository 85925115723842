import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined'
import { TabPanel } from '@mui/lab'
import TabList from '@mui/lab/TabList'
import { Box, Tab } from '@mui/material'
import { PropsWithChildren, SyntheticEvent } from 'react'

import { colors } from 'theme/colors'

import NewCommentField from './NewCommentField'

export type ChatTabOption = 'all' | 'resolved'

const colorProps = {
  color: colors.textLight,
  '.Mui-selected &': { color: colors.bgDarkTextLight },
} as const

type Props = PropsWithChildren<{
  onTabChange: (tab: ChatTabOption) => void
}>
export default function ChatTabs({ children, onTabChange }: Props) {
  const handleChange = (event: SyntheticEvent, newValue: ChatTabOption) => {
    onTabChange(newValue)
  }
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: colors.borderDark }}>
        <TabList
          centered
          textColor="primary"
          selectionFollowsFocus
          onChange={handleChange}
          indicatorColor="secondary">
          <Tab
            disableRipple
            value="all"
            iconPosition="start"
            label="All Comments"
            sx={{ minHeight: 50 }}
            icon={<ChatBubbleOutlineOutlinedIcon fontSize="small" sx={colorProps} />}
          />
          <Tab
            disableRipple
            value="resolved"
            iconPosition="start"
            label="Resolved Comments"
            sx={{ minHeight: 50 }}
            icon={<MarkChatReadOutlinedIcon fontSize="small" sx={colorProps} />}
          />
        </TabList>
      </Box>
      <TabPanel value="all" sx={{ p: 0 }}>
        <NewCommentField />
      </TabPanel>
      {children}
    </>
  )
}
