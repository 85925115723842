import * as Types from '../types';

import { gql } from '@apollo/client';
import { CommentFieldsFragmentDoc } from './CommentFields.gen';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCommentMutationVariables = Types.Exact<{
  input: Types.CreateProjectCommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createProjectComment: { __typename?: 'CreateProjectCommentPayload', projectComment: { __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null } | null } | null };


export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateProjectCommentInput!) {
  createProjectComment(input: $input) {
    projectComment {
      ...CommentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;