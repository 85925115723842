import * as Types from '../types';

import { gql } from '@apollo/client';
import { ShortUserFieldsFragmentDoc } from '../user/ShortUserFields.gen';
export type CommentFieldsFragment = { __typename?: 'ProjectComment', id: string, content: string | number | boolean | Record<string, unknown> | unknown[] | null, section: Types.ProjectSection, parentId: string | null, createdAt: string, isArchived: boolean, user: { __typename?: 'User', id: string, accountId: string | null, role: string, email: string, avatarUrl: string | null, fullname: string, isTeamsAdmin: boolean } | null };

export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on ProjectComment {
  id
  content
  section
  parentId
  createdAt
  isArchived
  user {
    ...ShortUserFields
  }
}
    ${ShortUserFieldsFragmentDoc}`;