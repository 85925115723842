import { Stack } from '@mui/material'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import {
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createEditor, Descendant, Editor, Range, Transforms } from 'slate'
import { Editable, RenderElementProps, Slate, withReact } from 'slate-react'

import { routeToSection } from 'routes'

import { useCreateCommentMutation } from 'graphql/comment/CreateComment.gen'
import { useUpdateCommentMutation } from 'graphql/comment/UpdateComment.gen'
import { useProjectMembersLazyQuery } from 'graphql/npt/project/ProjectMembers.gen'

import { useProject } from 'components/projects/ProjectProvider'
import { useUser } from 'providers/UserProvider'

import { insertMention, replaceContent, searchContent, withMentions } from '../slate/editor'

import styles from './chat.module.css'
import ChatToolbar from './ChatToolbar'
import { MentionComponent } from './Mention'
import MentionsPortal from './MentionsPortal'
import MentionsStaticMenu from './MentionsStaticMenu'

type Props = {
  id?: string
  replyTo?: string
  onEdit?: () => void
  readOnly?: boolean
  placeholder?: string
  initialContent?: Descendant[]
}

export const emptyParagraph: Descendant = {
  type: 'paragraph',
  children: [{ text: '' }],
}
const defaultEditorState: Descendant[] = [emptyParagraph]

export default function ChatTextField({
  id,
  replyTo,
  readOnly,
  onEdit,
  placeholder,
  initialContent = defaultEditorState,
}: Props) {
  const router = useRouter()
  const { userId } = useUser()
  const { projectId } = useProject()
  const [addComment] = useCreateCommentMutation()
  const [updateComment] = useUpdateCommentMutation()
  const [fetchUsers, { data, loading }] = useProjectMembersLazyQuery({ variables: { projectId } })

  const [hasFocus, setFocus] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Editor state
  const [fieldValue, setFieldValue] = useState<Descendant[]>(initialContent)
  const portalRef = useRef<HTMLButtonElement | null>(null)
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState('')
  const [target, setTarget] = useState<Range | undefined>()

  // Editor engine
  const renderElement = useCallback(
    (props: RenderElementProps) => <MentionComponent {...props} />,
    []
  )
  const [editor] = useState(() => withMentions(withReact(createEditor())))

  // Page handler
  const section = useMemo(() => routeToSection(router.route), [router.route])

  // Member suggestions
  const teamMembers = useMemo(() => data?.users?.nodes ?? [], [data?.users?.nodes])
  const matchingPeople = useMemo(
    () =>
      teamMembers
        .filter((c) =>
          c.fullname
            .toLowerCase()
            .split(' ')
            .some((n) => n.startsWith(search.toLowerCase()))
        )
        .slice(0, 10),
    [search, teamMembers]
  )

  const selectPerson = useCallback(
    (index: number) => {
      if (target && matchingPeople[index]) {
        Transforms.select(editor, target)
        insertMention(editor, matchingPeople[index])
        setTarget(undefined)
        setAnchorEl(null)
      }
    },
    [matchingPeople, editor, target]
  )

  const selectPersonManually = useCallback(
    (index: number) => {
      const target = Editor.range(editor, Editor.end(editor, []), Editor.end(editor, []))
      if (target && teamMembers[index]) {
        Transforms.select(editor, target)
        insertMention(editor, teamMembers[index])
        setAnchorEl(null)
      }
    },
    [teamMembers, editor]
  )

  const handleManualMention = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
      if (!data) {
        // Fetch team members on demand
        await fetchUsers()
      }
    },
    [data, fetchUsers]
  )

  const handleCancel = useCallback(() => {
    // Restore the content for existing comments and clean the editor for new ones
    replaceContent(editor, id ? initialContent : defaultEditorState)
    setFocus(false)
    if (onEdit) {
      onEdit()
    }
  }, [editor, id, initialContent, onEdit])

  const handleSubmit = useCallback(async () => {
    if (!userId) {
      return
    }
    // Don't store empty or initial state
    if (isEqual(fieldValue, initialContent)) {
      return
    }
    const content = JSON.stringify(fieldValue)
    const refetchQueries = ['Comments']
    if (id) {
      await updateComment({
        refetchQueries,
        variables: { input: { id, patch: { content } } },
      })
      setFocus(false)
      if (onEdit) {
        onEdit()
      }
    } else {
      await addComment({
        refetchQueries,
        variables: {
          input: {
            projectComment: {
              userId,
              section,
              projectId,
              content,
              parentId: replyTo ?? null,
            },
          },
        },
      })
      handleCancel()
    }
  }, [
    id,
    onEdit,
    userId,
    section,
    replyTo,
    projectId,
    addComment,
    fieldValue,
    handleCancel,
    updateComment,
    initialContent,
  ])

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    async (event) => {
      // Check selection target
      if (target) {
        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault()
            const prevIndex = index >= matchingPeople.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          }
          case 'ArrowUp': {
            event.preventDefault()
            const prevIndex = index <= 0 ? matchingPeople.length - 1 : index - 1
            setIndex(prevIndex)
            break
          }
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            selectPerson(index)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(undefined)
            break
        }
      } else {
        if (event.ctrlKey && event.key === 'Enter') {
          await handleSubmit()
        }
      }
    },
    [matchingPeople.length, index, selectPerson, target, handleSubmit]
  )

  const onChange = useCallback(
    async (editorContent: Descendant[]) => {
      const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type)

      // Store editor state externally
      if (isAstChange) {
        setFieldValue(editorContent)
      }

      const { selection } = editor

      if (selection && Range.isCollapsed(selection)) {
        const { beforeMatch, afterMatch, beforeRange } = searchContent(editor, selection)

        if (beforeMatch && afterMatch) {
          if (!data) {
            // Fetch team members on demand
            await fetchUsers()
          }
          setTarget(beforeRange)
          setSearch(beforeMatch[1])
          setIndex(0)
          return
        }
      }

      setTarget(undefined)
    },
    [data, editor, fetchUsers]
  )

  const classNames = useMemo(() => {
    const classes = [styles.chatbox]
    if (readOnly) {
      classes.push(styles.readOnly)
    }
    if (replyTo) {
      classes.push(styles.reply)
    }
    return classes.join(' ')
  }, [readOnly, replyTo])

  const mentionOpen = useMemo(
    () => (target && hasFocus && matchingPeople.length > 0) ?? false,
    [hasFocus, matchingPeople.length, target]
  )

  // Sync editor with remote comment edits
  useEffect(() => {
    replaceContent(editor, initialContent)
  }, [editor, initialContent])

  return (
    <Stack direction="column" onBlur={() => setFocus(false)} onFocusCapture={() => setFocus(true)}>
      <Slate editor={editor} initialValue={fieldValue} onChange={onChange}>
        <Editable
          readOnly={readOnly}
          onKeyDown={onKeyDown}
          className={classNames}
          placeholder={placeholder}
          id={`comment-${id ?? 'new'}`}
          renderElement={renderElement}
        />
      </Slate>
      {/* Mentions menu opened from typing */}
      <MentionsPortal
        loading={loading}
        open={mentionOpen}
        selectedIndex={index}
        people={matchingPeople}
        anchorEl={portalRef.current}
        onSelect={(i) => selectPerson(i)}
        onClose={() => {
          setAnchorEl(null)
          setTarget(undefined)
        }}
      />
      {/* Mentions menu opened by @ button */}
      <MentionsStaticMenu
        selectedIndex={0}
        loading={loading}
        anchorEl={anchorEl}
        people={teamMembers}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onSelect={(i) => selectPersonManually(i)}
      />
      {!readOnly && (
        <ChatToolbar
          mentionRef={portalRef}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onMention={handleManualMention}
          open={hasFocus || !isEqual(fieldValue, defaultEditorState)}
        />
      )}
    </Stack>
  )
}
