import axios from 'axios'
import { NodesType } from 'ts-utils'

import { ProjectSurveyDataType } from 'pages/npt/projects/[slug]/questions'

import { UserLanguagesQuery } from 'graphql/queries/userLanguages.gen'

export const formatNumber = (value: string) => {
  return parseInt(value).toLocaleString('en-US')
}

export const formatMoney = (value: number, currency = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value)
}

export const isValidGuid = (guid: string) => {
  const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  return guidRegex.test(guid)
}

export const getInitials = (fullName?: string) => {
  if (!fullName) return ''
  return fullName
    .split(' ')
    .map((first) => first.substring(0, 1))
    .join('')
    .toUpperCase()
}

export const getFirstNameLastInitial = (fullName?: string) => {
  if (!fullName) return ''

  return fullName
    .split(' ')
    .map((name, index) => (index === 0 ? name : name.substring(0, 1).toUpperCase()))
    .join(' ')
}

export const getHexColor = (
  columnNumber: number,
  rowNumber: number,
  selected: boolean,
  state: ProjectSurveyDataType['state']
) => {
  if (
    (rowNumber === 5 || rowNumber === 6) &&
    (columnNumber === 5 || columnNumber === 6) &&
    (state === 'pending' || !selected)
  ) {
    return 'center-quadrant'
  } else if ((rowNumber === 5 && columnNumber < 5) || (rowNumber < 5 && columnNumber === 5)) {
    return 'top-left-border-quadrant'
  } else if ((rowNumber <= 4 && columnNumber <= 4) || (rowNumber === 5 && columnNumber === 5)) {
    return 'top-left-quadrant'
  } else if ((rowNumber === 5 && columnNumber > 6) || (rowNumber < 5 && columnNumber === 6)) {
    return 'top-right-border-quadrant'
  } else if ((rowNumber <= 4 && columnNumber > 6) || (rowNumber === 5 && columnNumber === 6)) {
    return 'top-right-quadrant'
  } else if ((rowNumber === 6 && columnNumber < 5) || (rowNumber > 6 && columnNumber === 5)) {
    return 'bottom-left-border-quadrant'
  } else if ((rowNumber >= 7 && columnNumber <= 4) || (rowNumber === 6 && columnNumber === 5)) {
    return 'bottom-left-quadrant'
  } else if ((rowNumber === 6 && columnNumber > 6) || (rowNumber > 6 && columnNumber === 6)) {
    return 'bottom-right-border-quadrant'
  } else if ((rowNumber > 6 && columnNumber > 6) || (rowNumber === 6 && columnNumber === 6)) {
    return 'bottom-right-quadrant'
  }
}
export const capitalizeFirst = (str = '') => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

export const searchIncludes = (target: string, value: string) =>
  target.toLowerCase().includes(value.toLowerCase())

export const generateEmptyFormState = <T extends string>(keys: T[]) => {
  return [
    keys.reduce(
      (acc, cur) => {
        acc[cur] = ''
        return acc
      },
      {} as Record<T, string>
    ),
  ]
}

export const convertToSnakeCase = (input: string) => {
  return input
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function bytesToSize(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), sizes.length - 1)
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / 1024 ** i).toFixed(1)}${sizes[i]}`
}

// Convert the file extensions to an array of upper cased file extensions
// Input:
// {
//   'application/vnd.ms-powerpoint': ['.ppt'],
//   'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
//   'application/pdf': ['.pdf'],
// }
//
// Output:
// ['PPT', 'PPTX' 'PDF']
export function getFileTypes(acceptedMimeTypes: { [key: string]: string[] }) {
  return Object.values(acceptedMimeTypes).flatMap((types) =>
    types.map((type) => type.slice(1).toUpperCase())
  )
}

export function getFileType<T extends string = string>(filename: string): T {
  return filename.slice(filename.lastIndexOf('.') + 1).toUpperCase() as T
}

// TODO: fix the type definition of `f`. It should somehow describe "any" function
export function callableOnlyOnce(this: unknown, f: (...args: unknown[]) => unknown) {
  let called = false

  const callableOnlyOnce = (...args: Parameters<typeof f>) => {
    if (called) return
    called = true
    return f.apply(this, args)
  }

  return callableOnlyOnce
}

export const validateEmail = (email: string): boolean => {
  const match = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

  return (match?.length ?? 0) > 0
}

// Used to split a string into multiple lines for nivo graph labels
export const graphLabelTspanGroups = (value: string, maxLineLength = 15, maxLines = 2) => {
  // Split the value into words
  const words = value.split(' ')

  // Reduce the words into lines of no more than maxLineLength characters
  const lines = words.reduce(
    (acc, word) => {
      // Get the last line in acc
      const lastLine = acc[acc.length - 1]

      // If adding the next word exceeds the maxLineLength, add a new line
      if ((lastLine + word).length > maxLineLength) {
        acc.push(word)
      } else {
        // Else, add the word to the last line
        acc[acc.length - 1] += ' ' + word
      }

      return acc
    },
    ['']
  )

  // Slice the lines array to include at most maxLines lines
  // return the lines as an array of tspan groups
  return lines.slice(0, maxLines).map((lineText, i) => ({
    x: 0,
    dy: i * 15,
    key: i,
    text: i === 1 && lines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText,
  }))
}

export const formatEnumString = (input: string) => {
  return input
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const getLanguageFromCode = (code: string) => {
  const languageMap: Record<string, string> = {
    en: 'English',
    es: 'Spanish',
    zh: 'Chinese',
  }
  const lowerCode = code.toLowerCase()
  return Object.entries(languageMap).find(([key]) => lowerCode.startsWith(key))?.[1] || code
}

export type UserLanguage = NodesType<UserLanguagesQuery['userLanguages']>

export const getLanguageName = (languageCode: string, languages: UserLanguage[]) => {
  return languages.find((language) => language.languageCode === languageCode)?.name || ''
}

export const range = (size: number, startAt = 0) => {
  return [...Array(size - startAt + 1).keys()].map((i) => i + startAt)
}

export const getFileNameFromUrl = (url: string | { url: string }) => {
  const finalUrl = typeof url == 'string' ? url : url.url
  const urlObject = new URL(finalUrl)

  let pathname = urlObject.pathname

  // Remove any trailing slashes from the pathname
  pathname = pathname.replace(/\/+$/, '')

  // Split the pathname by '/' and get the last part (the filename)
  const segments = pathname.split('/')
  const fileName = segments.pop()

  return decodeURI(fileName as string)
}

export const downloadFileFromUrl = async (
  fileUrl: string,
  fileName: string = '',
  openFile = false
) => {
  try {
    const response = await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob',
    })

    const blob = response.data

    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl

    if (!fileName) {
      const urlSegments = fileUrl.split('/')
      fileName = urlSegments[urlSegments.length - 1] || 'download-file'
    }

    if (openFile) {
      link.setAttribute('target', '_blank')
    } else {
      link.setAttribute('download', fileName)
    }

    document.body.appendChild(link)
    link.click()
    link.remove()

    window.URL.revokeObjectURL(downloadUrl)
  } catch (error) {
    console.error('There was an error downloading the file:', error)
  }
}
