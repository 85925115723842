import { FormHelperText } from '@mui/material'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
  name: string
} & InputBaseProps
export default function ControlInputBase({ name, ...props }: Props) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <InputBase fullWidth error={!!error} {...field} {...props} />
            {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )
      }}
    />
  )
}
