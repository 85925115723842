import { Stack } from '@mui/material'
import groupBy from 'lodash/groupBy'
import { MouseEvent, useMemo, useState } from 'react'

import { ProjectTabs, tabToSection } from 'routes'

import { CommentFieldsFragment } from 'graphql/comment/CommentFields.gen'

import ChatButton from 'components/layouts/nav/ChatButton'

import StyledDrawer from '../drawer/StyledDrawer'

import ChatMessage from './ChatMessage'
import ChatTabs, { ChatTabOption } from './ChatTabs'
import MessageGroup from './MessageGroup'

type Props = {
  items: CommentFieldsFragment[]
  onTabChange: (tab: ChatTabOption) => void
}

export default function ChatDrawer({ items, onTabChange }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget))
  }
  const handleClose = () => setAnchorEl(null)

  const groupedMessages = useMemo(
    // Group by section
    () => groupBy(items, ({ section }) => section),
    [items]
  )

  return (
    <>
      <ChatButton
        onClick={handleClick}
        badgeProps={{
          badgeContent: items.length,
          invisible: Boolean(anchorEl),
        }}
      />
      <StyledDrawer onClose={handleClose} open={Boolean(anchorEl)}>
        <ChatTabs onTabChange={onTabChange}>
          <Stack direction="column">
            {/* <pre>{JSON.stringify(groupedMessages, null, 2)}</pre> */}
            {Object.values(ProjectTabs)
              .filter((k) => typeof k === 'string')
              .map((tab) => {
                const section = tabToSection(tab)
                const messages = groupedMessages[section] ?? []
                if (messages.length === 0) {
                  return null
                }
                return (
                  <MessageGroup section={section} key={section}>
                    {messages.map((message) => (
                      <ChatMessage key={message.id} {...message} />
                    ))}
                  </MessageGroup>
                )
              })}
          </Stack>
        </ChatTabs>
      </StyledDrawer>
    </>
  )
}
